// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps {
  color: #fff;
  position: fixed;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 0;
}
.steps__title span {
  font-size: 24px;
  font-weight: 500;
}
.steps__text {
  margin-top: 10px;
  margin-bottom: 47px;
  color: #bfbfbf;
  max-width: 205px;
}
.steps__count {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}
.steps__count span {
  color: #bfbfbf;
}
.steps__btn {
  width: 345px;
}
.steps__btn button {
  width: 100%;
  color: #000;
  padding: 16px;
  border-radius: 10px;
  background-color: #5cee9c;
  border: none;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Steps/Steps.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,OAAA;AACF;AACI;EACE,eAAA;EACA,gBAAA;AACN;AAEE;EACE,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AACI;EACE,cAAA;AACN;AAEE;EACE,YAAA;AAAJ;AACI;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,yBAAA;AACN","sourcesContent":[".steps {\n  color: #fff;\n  position: fixed;\n  bottom: 50px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  left: 0;\n  &__title {\n    span {\n      font-size: 24px;\n      font-weight: 500;\n    }\n  }\n  &__text {\n    margin-top: 10px;\n    margin-bottom: 47px;\n    color: #bfbfbf;\n    max-width: 205px;\n  }\n  &__count {\n    margin-bottom: 20px;\n    font-size: 14px;\n    font-weight: 500;\n    span {\n      color: #bfbfbf;\n    }\n  }\n  &__btn {\n    width: 345px;\n    button {\n      width: 100%;\n      color: #000;\n      padding: 16px;\n      border-radius: 10px;\n      background-color: #5cee9c;\n      border: none;\n      text-transform: uppercase;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
