import React from "react";
import { motion } from "framer-motion";

import "./Second.scss";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";
import { Steps } from "../../Steps";
import {
  animationBottom,
  animationCenter,
  animationTop,
} from "../../../../utils/animation";

export const SecondScreen = () => {
  return (
    <div className="second-screen">
      <motion.div
        className="second-screen__balance"
        initial="hidden"
        animate="visible"
        viewport={{ once: true }}
      >
        <motion.div variants={animationCenter} custom={1.5}>
          <BalanceProfitCard />
        </motion.div>
        <motion.div variants={animationCenter} custom={1.5}>
          <BalanceProfitRemainingCard />
        </motion.div>
      </motion.div>
      <Steps
        path="/onboarding/3"
        title="Balance"
        subtitle="Проверяйте общий и обычный баланс"
        page="1"
      />
    </div>
  );
};
