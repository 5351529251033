// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eights-screen__coin {
  display: flex;
  align-items: center;
}
.eights-screen__coin span {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Eights/Eights.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACN","sourcesContent":[".eights-screen {\n  &__coin {\n    display: flex;\n    align-items: center;\n    span {\n      font-size: 24px;\n      font-weight: 500;\n      color: #ffffff;\n      margin-left: 5px;\n      margin-right: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
