// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #171717;
  padding: 20px 15px;
  top: 5px;
}
.toolbar_trade {
  background-color: #242830;
  padding: 0;
  margin-bottom: 30px;
}
.toolbar_trade .toolbar__header {
  border-bottom: 1px solid #5b5b5b;
  padding-bottom: 15px;
}
.toolbar__header {
  overflow-x: scroll;
  display: flex;
}
.toolbar__header-tabs {
  display: flex;
  align-items: center;
}
.toolbar__header-tab {
  padding: 10px;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}
.toolbar__header-tab:last-child {
  margin-right: 0;
}
.toolbar__header-tab.active {
  background-color: #bfbfbf;
  border-radius: 12px;
  color: #0f0f0f;
}
.toolbar__header-search {
  padding: 10px;
  background-color: #bfbfbf;
  border-radius: 12px;
  color: #060606;
  display: flex;
}
.toolbar__header-search span {
  display: inline-block;
  border-left: 1px solid #a7a7a7;
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Toolbar/Toolbar.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,QAAA;AACF;AAAE;EACE,yBAAA;EACA,UAAA;EACA,mBAAA;AAEJ;AADI;EACE,gCAAA;EACA,oBAAA;AAGN;AAAE;EACE,kBAAA;EAEA,aAAA;AACJ;AAAI;EACE,aAAA;EACA,mBAAA;AAEN;AAAI;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAEN;AADM;EACE,eAAA;AAGR;AADM;EACE,yBAAA;EACA,mBAAA;EACA,cAAA;AAGR;AAAI;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;AAEN;AADM;EACE,qBAAA;EACA,8BAAA;EACA,kBAAA;AAGR","sourcesContent":[".toolbar {\n  margin-top: 20px;\n  margin-bottom: 10px;\n  background-color: #171717;\n  padding: 20px 15px;\n  top: 5px;\n  &_trade {\n    background-color: #242830;\n    padding: 0;\n    margin-bottom: 30px;\n    .toolbar__header {\n      border-bottom: 1px solid #5b5b5b;\n      padding-bottom: 15px;\n    }\n  }\n  &__header {\n    overflow-x: scroll;\n\n    display: flex;\n    &-tabs {\n      display: flex;\n      align-items: center;\n    }\n    &-tab {\n      padding: 10px;\n      color: #bfbfbf;\n      font-size: 14px;\n      font-weight: 500;\n      margin-right: 10px;\n      &:last-child {\n        margin-right: 0;\n      }\n      &.active {\n        background-color: #bfbfbf;\n        border-radius: 12px;\n        color: #0f0f0f;\n      }\n    }\n    &-search {\n      padding: 10px;\n      background-color: #bfbfbf;\n      border-radius: 12px;\n      color: #060606;\n      display: flex;\n      span {\n        display: inline-block;\n        border-left: 1px solid #a7a7a7;\n        padding-left: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
