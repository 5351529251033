import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { animationBottom } from "../../utils/animation";

import { RatingTable } from "../../components/RatingTable";

import arrowUp from "../../icons/earn-arrow.svg";

import "./Leaderboard.scss";
import { useAppDispatch } from "../../store";
import { assetsAPI } from "../../api/assets";
import useUserId from "../../hooks/userUserId";

const Leaderboard = () => {
  const userId = useUserId();

  const [user, setUser] = useState(null);

  useEffect(() => {
    (async function () {
      const user = await assetsAPI.getUserPosition(userId);

      setUser(user.data);
    })();
  }, [setUser]);
  return (
    <div className="leaderboard">
      <div className="container">
        <div className="live-rating">
          <RatingTable />
        </div>
        <div className="leaderboard__me active">
          <motion.div
            variants={animationBottom}
            custom={1.2}
            className="rating__item"
          >
            <div className="rating__place">{user?.position}</div>
            <p className="rating__name">Me</p>
            <p className="rating__balance">{user?.usdt.toFixed(2)}</p>
            <p className="rating__profit">
              <img src={arrowUp} alt="" />
              0,35%
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
