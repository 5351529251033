// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ninth-screen__coin {
  display: flex;
  align-items: center;
}
.ninth-screen__coin span {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}
.ninth-screen__tabs {
  color: #fff;
  display: flex;
  justify-content: center;
}
.ninth-screen__tabs button {
  color: #5cee9c;
  border-bottom: 1px solid #5cee9c;
  width: 182px;
}
.ninth-screen__mock {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media screen and (max-height: 739px) {
  .ninth-screen__steps .steps {
    position: static;
    margin-top: 30px;
  }
  .ninth-screen .onboarding__btn {
    position: static;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Ninth/Ninth.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACN;AAEE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AAAJ;AACI;EACE,cAAA;EACA,gCAAA;EACA,YAAA;AACN;AAEE;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;;AAGA;EAGM;IACE,gBAAA;IACA,gBAAA;EAFN;EAKE;IACE,gBAAA;EAHJ;AACF","sourcesContent":[".ninth-screen {\n  &__coin {\n    display: flex;\n    align-items: center;\n    span {\n      font-size: 24px;\n      font-weight: 500;\n      color: #ffffff;\n      margin-left: 5px;\n      margin-right: 5px;\n    }\n  }\n  &__tabs {\n    color: #fff;\n    display: flex;\n    justify-content: center;\n    button {\n      color: #5cee9c;\n      border-bottom: 1px solid #5cee9c;\n      width: 182px;\n    }\n  }\n  &__mock {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n  }\n}\n@media screen and (max-height: 739px) {\n  .ninth-screen {\n    &__steps {\n      .steps {\n        position: static;\n        margin-top: 30px;\n      }\n    }\n    .onboarding__btn {\n      position: static;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
