import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetFileds } from "../../../types/user/assets";

interface userFavouritesState {
  favourites: AssetFileds[];
}

const initialState: userFavouritesState = {
  favourites: [],
};

export const userFavouritesSlice = createSlice({
  name: "userFavourites",
  initialState,
  reducers: {
    addToFavourites(state, action: PayloadAction<AssetFileds>) {
      state.favourites = [...state.favourites, action.payload];
    },
    removeFromFavourites(state, action: PayloadAction<any>) {
      state.favourites = state.favourites.filter(
        (item) => item.name !== action.payload
      );
    },
  },
});

export const { addToFavourites, removeFromFavourites } =
  userFavouritesSlice.actions;
export const userFavouritesReducer = userFavouritesSlice.reducer;
