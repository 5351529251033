import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { useUpdatePositions } from "../../hooks/useUpdatePositions";
import { useUpdateHistory } from "../../hooks/useUpdateHistory";
import { useInterval } from "../../hooks/useInterval";
import { selectUser } from "../../store/selectors/user";
import { useAppDispatch } from "../../store";
import { setIsChartConnected } from "../../store/reducers/chart";
import { animationTop } from "../../utils/animation";

import { MainInfo } from "../../components/MainInfo";
import { AssetList } from "../../components/AssetList";

import "./Dashboard.scss";
import { Header } from "../../components/Header";
import { Toolbar } from "../../components/Toolbar";

export const Dashboard = () => {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      className="container"
      style={{ padding: 0 }}
    >
      <div className="dashboard">
        <motion.div
          custom={2.4}
          variants={animationTop}
          style={{ padding: "0 15px" }}
        >
          <Header />
        </motion.div>

        <MainInfo />
        <div className="dashboard__toolbar">
          <Toolbar searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>

        <AssetList searchValue={searchValue} />
      </div>
    </motion.div>
  );
};
