import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { selectUser } from "../../store/selectors/user";
import { AssetItem } from "../../types/user/assets";
import favouriteAdded from "../../icons/toolbar-favourite.svg";

import { ReactComponent as ArrowSvg } from "../../icons/arrow.svg";

import Skeleton from "../Skeleton";
import TradingViewWidget from "../ConstructorChart/TradingViewWidget";

import "./AssetRow.scss";
import { selectActiveTab } from "../../store/selectors/trade";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import {
  setIsVisibleTradeModal,
  setSelectedTradeValue,
} from "../../store/reducers/trade";

interface AssetRowProps {
  currentToken: AssetItem;
  delay: number;
  className?: string;
}

export const AssetRow = ({ currentToken, delay, className }: AssetRowProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useSelector(selectActiveTab);

  const [last24Change, setLast24Change] = useState("0.00");
  const [price, setPrice] = useState<any>("0");

  const onCoinClick = () => {
    if (location.pathname === "/trade") {
      dispatch(setSelectedTradeValue(currentToken));
      dispatch(setIsVisibleTradeModal(false));
    }
    if (location.pathname === "/") {
      dispatch(setSelectedTradeValue(currentToken));
      // dispatch(setIsVisibleTradeModal(false));
      navigate("/trade");
    }
  };

  useEffect(() => {
    setPrice("0");
    setLast24Change("0.00");

    fetch(
      `https://api.binance.com/api/v1/ticker/24hr?symbol=${currentToken?.symbol.toUpperCase()}USDT`
    )
      .then((response) => response.json())
      .then((data) => {
        setPrice(
          data.symbol === "PEPEUSDT"
            ? Number(data.lastPrice)
            : Number(data.lastPrice).toFixed(4)
        );
        setLast24Change(data.priceChangePercent);
      });
  }, [currentToken]);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://fstream.binance.com/stream?streams=${currentToken?.symbol.toLowerCase()}usdt@ticker`
    );

    ws.onopen = () => {
      ws.onmessage = (event) => {
        const { data } = JSON.parse(event.data);

        setPrice(
          data.s === "PEPEUSDT" ? Number(data.c) : Number(data.c).toFixed(4)
        );
        setLast24Change(data.P);
      };
    };
    return () => {
      ws.close();
    };
  }, [currentToken]);

  const lastDayResult = useMemo(() => {
    const parsedLast24Change = Number(last24Change).toFixed(2);

    return Number(last24Change) > 0 ? +parsedLast24Change : parsedLast24Change;
  }, [last24Change]);

  if (!price) return null;

  return (
    <motion.div
      initial={{ transform: "translateX(100%)", opacity: 0 }}
      animate={{ transform: "translateY(0)", opacity: 1 }}
      transition={{ delay: delay * 0.1 }}
      viewport={{ once: true }}
      className={classNames("asset-list__row")}
      onTouchStart={onCoinClick}
    >
      <div className={classNames("asset-list__row-info", className)}>
        <div className="asset-list__name">
          <div className="asset-list__name-flex">
            {activeTab === "favourites" ? (
              <img className="asset-list__icon" src={favouriteAdded} alt="" />
            ) : (
              <img
                className="asset-list__icon"
                src={`cryptoicons/${currentToken?.symbol}.png`}
                alt=""
              />
            )}
            <span className="asset-list__token-name">
              {currentToken?.symbol}
            </span>
          </div>
        </div>

        <div className="asset-list__price">
          <span>
            {price === "0" ? (
              <Skeleton width={70} height={30} />
            ) : (
              `$${Number(price).toFixed(0)}`
            )}
          </span>
          <div
            className={classNames(
              "asset-list__last",
              "asset-list__name-price",
              {
                "asset-list__last_green": Number(last24Change) > 0,
                "asset-list__last_red": Number(last24Change) < 0,
              }
            )}
          >
            {last24Change === "0.00" ? (
              <Skeleton width={70} height={30} />
            ) : (
              `${lastDayResult}%`
            )}
          </div>
        </div>

        <div
          className={classNames("asset-list__last", "asset-list__last_hide", {
            "asset-list__last_green": Number(last24Change) > 0,
            "asset-list__last_red": Number(last24Change) < 0,
          })}
        >
          <div className="asset-list__value-label">Last</div>
          {last24Change === "0.00" ? (
            <Skeleton width={70} height={30} />
          ) : (
            `${lastDayResult}%`
          )}
        </div>
      </div>
    </motion.div>
  );
};
