import React from "react";

import { OnboardingHeader } from "../../Header";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";
import { Steps } from "../../Steps";

import "./First.scss";

export const FirstScreen = () => {
  return (
    <div className="first-screen">
      <div className="first-screen__balance">
        <BalanceProfitCard className="first-screen-profit" />
        <BalanceProfitRemainingCard className="first-screen-profit__remaining" />
      </div>
      <Steps
        path="/onboarding/2"
        title="Balance"
        subtitle="Проверяйте общий и обычный баланс"
        page="1"
      />
    </div>
  );
};
