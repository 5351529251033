// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trade {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-height: 100vh;
  padding: 30px 15px 74px 15px;
}
.trade__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.trade__info_up {
  display: flex;
  align-items: flex-end;
  gap: 3px;
}
.trade__info_up_number {
  font-size: 24px;
  font-weight: 500;
  color: #bfbfbf;
}
.trade__info_up_text {
  font-size: 12px;
  font-weight: 500;
  color: #bfbfbf;
  margin-bottom: 3px;
}
.trade__info_down {
  display: flex;
  align-items: center;
}
.trade__info_down_changes {
  font-size: 14px;
  font-weight: 500;
  color: #5cee9c;
}`, "",{"version":3,"sources":["webpack://./src/pages/Trade/Trade.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;EACA,iBAAA;EACA,4BAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;AAAJ;AACI;EACE,aAAA;EACA,qBAAA;EACA,QAAA;AACN;AAAM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAER;AAAM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAER;AACI;EACE,aAAA;EACA,mBAAA;AACN;AAAM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAER","sourcesContent":[".trade {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  width: 100%;\n  min-height: 100vh;\n  padding: 30px 15px 74px 15px;\n\n  &__header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  &__info {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    &_up {\n      display: flex;\n      align-items: flex-end;\n      gap: 3px;\n      &_number {\n        font-size: 24px;\n        font-weight: 500;\n        color: #bfbfbf;\n      }\n      &_text {\n        font-size: 12px;\n        font-weight: 500;\n        color: #bfbfbf;\n        margin-bottom: 3px;\n      }\n    }\n    &_down {\n      display: flex;\n      align-items: center;\n      &_changes {\n        font-size: 14px;\n        font-weight: 500;\n        color: #5cee9c;\n      }\n    }\n  }\n\n  &__graph {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
