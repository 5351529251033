import React from "react";
import { format } from "date-fns";

import btcIcon from "../../../public/cryptoicons/BTC.png";

import percentUp from "../../icons/percent-up.svg";
import percentDown from "../../icons/percent-down.svg";
import chartUp from "../../icons/chart-up.png";
import chartDown from "../../icons/chart-down.png";
import checkedIcon from "../../icons/checked.svg";

import "./HistoryItem.scss";
import { Skeleton } from "@mui/material";
import classNames from "classnames";

interface Props {
  id?: number;
  asset_tag?: string;
  price?: number;
  amount?: number;
  stop_loss?: string;
  take_profit?: string;
  date?: string;
  profitPercent?: number;
  type?: string;
}

const openLongType = "open long position";
const openShortType = "open short position";
const closeLongType = "close long position";
const closeShortType = "close short position";

export const HistoryItem = ({
  asset_tag,
  price,
  amount,
  take_profit,
  date,
  profitPercent,
  stop_loss,
  type,
}: Props) => {
  const formatedDate = format(date, "yyyy-MM-dd hh:mm");

  return (
    <div className="history-item">
      <div className="history-item__row">
        <div className="history-item__info">
          <div className="history-item__enter-date">{formatedDate}</div>

          <div className="history-item__info-coin">
            <img
              className="history-item__info-coin-icon"
              src={`cryptoicons/${asset_tag.toUpperCase()}.png`}
              alt="коин"
            />
            <div className="history-item__info-coin-symbol">
              {asset_tag.toUpperCase()}
            </div>
          </div>
          <div className="history-item__info-price">
            <p>
              <span>{amount}</span> BTC | <span>{price}</span> USDT
            </p>
          </div>
          <div className="history-item__info-percent">
            <p>
              +0.83 <span>USDT</span> (+1.29%)
            </p>
          </div>
        </div>
        <div className="history-item__type-wrap">
          <div
            className={classNames(
              "history-item__type",
              type === openShortType ||
                type === closeLongType ||
                type === closeShortType
                ? "history-item__type_red"
                : ""
            )}
          >
            {type}
          </div>
          {take_profit || stop_loss ? (
            <>
              <div className="history-item__tp">
                TP/SL <img src={checkedIcon} alt="" />
              </div>
              <div className="history-item__diff">
                {take_profit ? take_profit : "0"} /{" "}
                {stop_loss ? stop_loss : "0"} USDT
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
