import React from 'react';
import classNames from 'classnames';

import './AssetsBar.scss';

interface AssetsBarProps {
  assets: any;
}

const AssetsBar = ({ assets }: AssetsBarProps) => {
  return (
    <div className='assets-bar'>
      {assets && (
        <div className='assets-bar__list'>
          <div
            className={classNames('assets-bar__bar', {
              'assets-bar__bar_one': assets.length === 1,
              'assets-bar__bar_not-one': assets.length > 1,
            })}
          >
            {assets.map((item: any, i: number) => {
              const total = assets.reduce((acc: any, obj: any) => acc + obj.usdt, 0);
              const percent = ((item.usdt / total) * 100).toFixed(0);

              return <div key={i} style={{ width: `${Number(percent)}%`, backgroundColor: item?.color }} />;
            })}
          </div>
          <div className='assets-bar__list-row'>
            {assets.map((item: any, i: number) => {
              return (
                <div className={'main-info__list-item'} key={i}>
                  <div style={{ backgroundColor: `${item?.color}` }} />
                  {item?.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetsBar;
