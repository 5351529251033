import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { selectIsChartConnected } from "../../store/selectors/chart";
import { AssetFileds } from "../../types/user/assets";

import { Terminal } from "./Terminal";

import "./TradingViewWidget.scss";

export enum Symbols {
  BTC = "btc",
  ETH = "eth",
  BNB = "bnb",
}

export interface Token {
  color: string;
  assets: [];
  symbol: Symbols;
  name: string;
  icon: JSX.Element;
  widget: string;
}

interface TradingViewWidgetProps {
  isOpen: boolean;
  currentToken: AssetFileds;
  currentPrice?: string;
  withTerminal?: boolean;
}

function TradingViewWidget({
  isOpen,
  currentToken,
  currentPrice,
  withTerminal,
}: TradingViewWidgetProps) {
  const container = useRef(null);
  const isChartConnected = useSelector(selectIsChartConnected);

  useEffect(() => {
    function createWidget() {
      if (container.current !== null && isOpen && "TradingView" in window) {
        new (window.TradingView as any).widget({
          autosize: true,
          symbol: `BINANCE:${currentToken.symbol.toLocaleUpperCase()}USDT`,
          interval: "1",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          enable_publishing: false,
          container_id: `tradingview_${currentToken.symbol.toLocaleUpperCase()}`,
        });
      }
    }

    if (isChartConnected) {
      createWidget();
    }
  }, [isChartConnected, isOpen, currentToken.symbol]);

  return (
    <div className={classNames("widget", { widget_opened: isOpen })}>
      <div
        className="widget-container"
        id={`tradingview_${currentToken.symbol.toLocaleUpperCase()}`}
        ref={container}
      />
      {withTerminal && (
        <Terminal currentPrice={currentPrice} currentToken={currentToken} />
      )}
    </div>
  );
}

export default TradingViewWidget;
