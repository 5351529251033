import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";
import { OnboardingTab } from "../../../../components/OnboardingTab";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";

import search from "../../../../icons/onboarding-search.png";

import { OnboardingCoin } from "../../../../components/OnboardingCoin";

import "./Sixth.scss";
import { animationCenter } from "../../../../utils/animation";

export const SixthScreen = () => {
  return (
    <motion.div
      className="sixth-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="sixth-screen__balance">
        <BalanceProfitCard />
        <BalanceProfitRemainingCard />
      </div>
      <div className="sixth-screen__tabs">
        <OnboardingTab title="Open positions" />
        <OnboardingTab title="History" />
      </div>
      <motion.div variants={animationCenter} custom={1.5} ignoreStrict={true}>
        <div className="sixth-screen__search">
          <img src={search} alt="" />
        </div>
        <div className="sixth-screen__coin">
          <OnboardingCoin />
          <OnboardingCoin />
        </div>
      </motion.div>

      <div className="sixth-screen__steps">
        <Steps path="/onboarding/7" page="3" />
      </div>
    </motion.div>
  );
};
