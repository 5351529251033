import React from "react";
import { useSelector } from "react-redux";
import { selectTgUser } from "../../store/selectors/user/tg";

import "./BalanceProfitRemainingCard.scss";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

interface Props {
  className?: string;
}

export const BalanceProfitRemainingCard = ({ className }: Props) => {
  const tgUser = useSelector(selectTgUser);
  const location = useLocation();

  return (
    <div className={classNames("balance-profit__remaining", className)}>
      <p className="balance-profit__remaining-title">Remaining balance:</p>
      <p className="balance-profit__remaining-value">
        {!location.pathname.includes("onboarding")
          ? tgUser?.usdt.toFixed(2)
          : "1,096,252.00"}{" "}
        <span>USDT</span>
      </p>
    </div>
  );
};
