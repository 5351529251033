import React from "react";

import bg from "../../icons/preloader-bg.png";
import gradient from "../../icons/preloader-gradient.png";

import "./Preloader.scss";

export const Preloader = () => {
  return (
    <div className="preloader">
      <div className="preloader__bg">
        <img src={bg} alt="" />
      </div>
      <div className="preloader__main">
        <div className="preloader__main-wrap">
          <span className="preloader__main-title">Welcome back!</span>
          <p className="preloader__main-text">
            Lorem ipsum dolor sit amet consectetur. Sit ac gravida tincidunt in
            ut sit blandit. Id.
          </p>
        </div>
      </div>
    </div>
  );
};
