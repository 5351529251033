import React from "react";

import arrowDown from "../../icons/arrow-down.svg";

import "./OnboardingTab.scss";
import classNames from "classnames";

interface Props {
  title: string;
  className?: any;
}

export const OnboardingTab = ({ title, className }: Props) => {
  return (
    <div className={classNames("onboarding-tab", className)}>
      <span>{title}</span>
      <img src={arrowDown} alt="стрелка-вниз" />
    </div>
  );
};
