import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface tgUserState {
  tgUser: any;
}

const initialState: tgUserState = {
  tgUser: null,
};

export const tgUserSlice = createSlice({
  name: "tgUser",
  initialState,
  reducers: {
    setTgUser(state, action: PayloadAction<any>) {
      state.tgUser = action.payload;
    },
  },
});

export const { setTgUser } = tgUserSlice.actions;
export const tgUserReducer = tgUserSlice.reducer;
