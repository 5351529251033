import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface chartState {
  isConnected: boolean;
}

const initialState: chartState = {
  isConnected: false,
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setIsChartConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
  },
});

export const { setIsChartConnected } = chartSlice.actions;
export const chartReducer = chartSlice.reducer;
