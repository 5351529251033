// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard {
  min-height: 100vh;
  padding-bottom: 30px;
  padding-top: 30px;
}
.leaderboard__me {
  margin-top: 70px;
}
.leaderboard__me.active {
  box-shadow: 0px 0px 19.3px 0px rgba(129, 161, 224, 0.6);
  border-radius: 12px;
}

.live-rating {
  max-width: 1162px;
  color: #fff;
}

.leaderboard-bottom {
  width: 1162px;
  height: 32px;
  font-size: 20px;
  color: #959595;
  margin: 0 auto 20px auto;
}

@media screen and (max-width: 991px) {
  .leaderboard {
    padding-bottom: 80px;
    flex-wrap: wrap;
    height: auto;
    background-position: bottom center;
    justify-content: center;
  }
  .trading-challenge {
    flex-basis: 100%;
    width: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Leaderboard/Leaderboard.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,oBAAA;EACA,iBAAA;AADF;AAEE;EACE,gBAAA;AAAJ;AACI;EACE,uDAAA;EACA,mBAAA;AACN;;AAIA;EACE,iBAAA;EACA,WAAA;AADF;;AAIA;EACE,aAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,wBAAA;AADF;;AAGA;EACE;IACE,oBAAA;IACA,eAAA;IACA,YAAA;IACA,kCAAA;IACA,uBAAA;EAAF;EAEA;IACE,gBAAA;IACA,WAAA;EAAF;AACF","sourcesContent":["@import \"../../variables.scss\";\n\n.leaderboard {\n  min-height: 100vh;\n  padding-bottom: 30px;\n  padding-top: 30px;\n  &__me {\n    margin-top: 70px;\n    &.active {\n      box-shadow: 0px 0px 19.3px 0px #81a1e099;\n      border-radius: 12px;\n    }\n  }\n}\n\n.live-rating {\n  max-width: 1162px;\n  color: #fff;\n}\n\n.leaderboard-bottom {\n  width: 1162px;\n  height: 32px;\n  font-size: 20px;\n  color: #959595;\n  margin: 0 auto 20px auto;\n}\n@media screen and (max-width: $tablet-xl) {\n  .leaderboard {\n    padding-bottom: 80px;\n    flex-wrap: wrap;\n    height: auto;\n    background-position: bottom center;\n    justify-content: center;\n  }\n  .trading-challenge {\n    flex-basis: 100%;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
