import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";

import btc from "../../../../icons/onboarding-coin.svg";
import arrowDown from "../../../../icons/arrow-down.svg";

import "./Seventh.scss";
import TradeSelect from "../../../../components/TradeSelect";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";

export const SeventhScreen = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);

    setTimeout(() => {
      navigate("/onboarding/9");
    }, 1000);
  };
  return (
    <motion.div
      className="seventh-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <motion.div variants={animationCenter} custom={1.5}>
        <div className="seventh-screen__coin">
          <TradeSelect />
        </div>
        <div className="seventh-screen__steps">
          <Steps
            path="/onboarding/8"
            title="Coins"
            subtitle="Проверяйте общий и обычный баланс"
            page="3"
            withoutButton={true}
          />
        </div>
      </motion.div>
      <div className="onboarding__btn ">
        <button onClick={() => onNavigate("/onboarding/8")}>
          {/* {Number(pathname) > 7 ? "Начать торговать" : "continue"} */}
          continue
        </button>
      </div>
    </motion.div>
  );
};
