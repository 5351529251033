import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isVisibleEarnDailyModal: boolean;
}

const initialState: State = {
  isVisibleEarnDailyModal: false,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setIsVisibleEarnDailyModal(state, action: PayloadAction<boolean>) {
      state.isVisibleEarnDailyModal = action.payload;
    },
  },
});

export const { setIsVisibleEarnDailyModal } = modalsSlice.actions;
export const modalsReducer = modalsSlice.reducer;
