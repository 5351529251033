import axios from "axios";

const earn = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
});

export const earnAPI = {
  fetchDailyRewards: (userId: number) => {
    return earn.post(`/daily/check_daily_awards?user_id=${userId}`);
  },
  claimDailyReward: (userId: number, day: string) => {
    return earn.post(
      `/daily/check_daily_awards/claim?user_id=${userId}&day=${day}`
    );
  },
  fetchSubRewards: (userId: number) => {
    return earn.get(`/sub_rewards/collected_rewards/${userId}`);
  },
  claimSubReward: (userId: number, task: string) => {
    return earn.get(`/sub_rewards/get_award/${userId}?task=${task}`);
  },
};
