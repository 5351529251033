import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";

import btc from "../../../../icons/onboarding-coin.svg";
import arrowDown from "../../../../icons/arrow-down.svg";
import mock from "../../../../icons/onboarding-mock.png";

import "./Ninth.scss";
import { Tab, Tabs } from "@mui/material";
import TradeOpenPosition from "../../../../components/TradeOpenPosition";
import TradeSelect from "../../../../components/TradeSelect";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store";
import { setIsOnboarding } from "../../../../store/reducers/loading";

export const NinthScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onNavigate = (path: string) => {
    navigate(path);
    dispatch(setIsOnboarding(false));
  };
  return (
    <motion.div
      className="ninth-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="ninth-screen__coin">
        <div className="eights-screen__coin">
          <TradeSelect />
        </div>
      </div>
      <motion.div variants={animationCenter} custom={1.5}>
        <div style={{ marginTop: 60 }}>
          <TradeOpenPosition />
        </div>
        <div className="ninth-screen__steps">
          <Steps
            path="/"
            title="Open position"
            subtitle="Проверяйте общий и обычный баланс"
            page="5"
            isSkipping={true}
            withoutButton={true}
          />
        </div>
      </motion.div>
      <div className="onboarding__btn ">
        <button onClick={() => onNavigate("/")}>Начать торговать</button>
      </div>
    </motion.div>
  );
};
