import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetFileds } from "../../types/user/assets";

interface assetsState {
  assets: AssetFileds[];
  testAssets: AssetFileds[];
}

const initialState: assetsState = {
  assets: [],
  testAssets: [],
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssets(state, action: PayloadAction<any>) {
      state.assets = action.payload;
    },
    setTestAssets(state, action: PayloadAction<any>) {
      state.testAssets = action.payload;
    },
  },
});

export const { setAssets, setTestAssets } = assetsSlice.actions;
export const assetsReducer = assetsSlice.reducer;
