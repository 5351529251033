import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../store";
import { setIsOnboarding } from "../../../store/reducers/loading";

import "./Steps.scss";

interface Props {
  path: string;
  title?: string;
  subtitle?: string;
  page: string;
  isSkipping?: boolean;
  withoutButton?: boolean;
}

export const Steps = ({
  path,
  title,
  subtitle,
  page,
  isSkipping,
  withoutButton,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathname = location.pathname.split("/")[2];
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const onNavigate = () => {
    if (isSkipping) {
      dispatch(setIsOnboarding(false));
      navigate("/");
    }

    navigate(path);

    if (path === "/onboarding/2") {
      setTimeout(() => {
        navigate("/onboarding/3");
      }, 1000);
    }
    // if (path === "/onboarding/4") {
    //   setTimeout(() => {
    //     navigate("/onboarding/5");
    //   }, 1000);
    // }
    // if (path === "/onboarding/6") {
    //   setTimeout(() => {
    //     navigate("/onboarding/7");
    //   }, 1000);
    // }
    // if (path === "/onboarding/8") {
    //   setTimeout(() => {
    //     navigate("/onboarding/9");
    //   }, 1000);
    // }
  };

  useEffect(() => {
    if (
      location.pathname === "/onboarding/2" ||
      location.pathname === "/onboarding/4" ||
      location.pathname === "/onboarding/6" ||
      location.pathname === "/onboarding/8"
    ) {
      setIsBtnDisabled(true);
      setTimeout(() => {
        setIsBtnDisabled(false);
      }, 1000);
    }
  }, [location]);

  return (
    <div className="steps">
      <div className="steps__title">
        <span>{title}</span>
      </div>
      <div className="steps__text">
        <p>{subtitle}</p>
      </div>
      <div className="steps__count">
        <p>
          {page}/<span>5</span>
        </p>
      </div>
      {!withoutButton && (
        <div className="steps__btn">
          <button onClick={onNavigate} disabled={isBtnDisabled}>
            {Number(pathname) > 7 ? "Начать торговать" : "continue"}
          </button>
        </div>
      )}
    </div>
  );
};
