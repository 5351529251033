import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../store";
import { fetchUserAssets } from "../../store/actionCreators/user/assets";
import { selectUserAssets } from "../../store/selectors/user/assets";
import {
  selectHasOpenedPositions,
  selectUserBalanceLoading,
} from "../../store/selectors/user/balance";
import {
  setHasOpenedPositions,
  setUserBalanceLoading,
  setUserTotalBalance,
} from "../../store/reducers/user/balance";
import { selectUser } from "../../store/selectors/user";

import BalanceProfit from "../BalanceProfit";
import Skeleton from "../Skeleton/";

import "./UserBalance.scss";
import useUserId from "../../hooks/userUserId";
import { selectTgUser } from "../../store/selectors/user/tg";
import { CircularProgress } from "@mui/material";

export const UserBalance = () => {
  const dispatch = useAppDispatch();
  const userId = useUserId();

  const hasOpenedPositions = useSelector(selectHasOpenedPositions);
  // const user = useSelector(selectUser);
  const tgUser = useSelector(selectTgUser);
  const userAssets = useSelector(selectUserAssets);
  const isLoading = useSelector(selectUserBalanceLoading);

  const [currentBalance, setCurrentBalance] = useState<any>([]);

  // useEffect(() => {
  //   dispatch(fetchUserAssets(userId));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (hasOpenedPositions) {
  //     dispatch(setUserBalanceLoading(true));
  //     setTimeout(() => {
  //       dispatch(setUserBalanceLoading(false));
  //     }, 5000);
  //   }
  // }, [hasOpenedPositions]);

  // useEffect(() => {
  //   dispatch(setUserTotalBalance(0));

  //   if (hasOpenedPositions) {
  //     const id = setInterval(() => {
  //       getTotalBalance(currentBalance).then((totalBalance) => {
  //         if (totalBalance) {
  //           dispatch(fetchUserAssets(userId));
  //           dispatch(setUserTotalBalance(totalBalance.toFixed(6)));
  //         }
  //       });
  //     }, 3000);

  //     return () => {
  //       clearInterval(id);
  //     };
  //   } else {
  //     if (tgUser) {
  //       dispatch(fetchUserAssets(tgUser));
  //       dispatch(setUserTotalBalance(tgUser.usdt));
  //     }
  //   }
  // }, [currentBalance, hasOpenedPositions, userId]);

  // async function getTotalBalance(balances: any) {
  //   if (balances.length > 0) {
  //     const symbols = balances.map((balance: any) => `"${balance.symbol}USDT"`);
  //     const uniqueSymbols = [
  //       ...new Map(symbols.map((item) => [item, item])).values(),
  //     ];

  //     const prices = await fetch(
  //       `https://api.binance.com/api/v3/ticker/price?symbols=[${uniqueSymbols.join(
  //         ","
  //       )}]`
  //     ).then((response) => response.json());

  //     const totalBalance = balances.reduce((total: any, balance: any) => {
  //       const price = prices.find(
  //         (p: any) => p.symbol === `${balance.symbol}USDT`
  //       ).price;
  //       const usdValue = parseFloat(price) * parseFloat(balance.amount);

  //       return total + usdValue;
  //     }, 0);
  //     return totalBalance;
  //   }
  // }

  return (
    <div className="user-balance">
      <>
        {!isLoading ? (
          <>
            <BalanceProfit />
          </>
        ) : (
          <div
            style={{ minHeight: 102, display: "flex", alignItems: "center" }}
          >
            <CircularProgress color="success" size={70} thickness={2} />
          </div>
        )}
      </>
    </div>
  );
};
