import React, { useEffect, useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { assetsAPI } from "../../api/assets";
import { useAppDispatch } from "../../store";
import {
  selectIsShowingPositions,
  selectUserPositions,
} from "../../store/selectors/user/positions";
import { setUserPositions } from "../../store/reducers/user/positions";
import { selectUser } from "../../store/selectors/user";

import { animationTop } from "../../utils/animation";
import { PositionItem } from "../PositionItem";
import { History } from "../History";
import { UserBalance } from "../UserBalance";
import AssetsBar from "../AssetsBar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./MainInfo.scss";
import useUserId from "../../hooks/userUserId";

export const MainInfo = () => {
  const userId = useUserId();
  const dispatch = useAppDispatch();
  const positions = useSelector(selectUserPositions);
  const isShowingPositions = useSelector(selectIsShowingPositions);

  useEffect(() => {
    const someArray: any = [];
    (async () => {
      const { data } = await assetsAPI.getPositions(userId);

      data.map((item: any) => {
        const token: any = item;

        if (Number(token.amount) > 0) {
          someArray.push(token);
        }
      });
      dispatch(setUserPositions(someArray));
    })();
  }, [dispatch]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      className={cn("main-info", { "main-info_opened": isShowingPositions })}
    >
      <div className="main-info__wrap">
        <div className="main-info__head">
          <motion.div custom={2} variants={animationTop}>
            <UserBalance />
          </motion.div>

          <div className="main-info__tabs">
            <motion.div
              custom={1.4}
              variants={animationTop}
              style={{ marginBottom: 10 }}
            >
              <Accordion className="main-info__tab">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="main-info__tab-header">
                    <span>Open positions</span>
                    {/* <img src={arrowDownIcon} alt="" /> */}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={cn("main-info__positions-list")}>
                    {positions.length > 0 ? (
                      positions.map((position: any) => {
                        return (
                          <PositionItem
                            key={position.id}
                            isButton={true}
                            currentToken={position}
                            buyValueUsdt={position.usdt.toString()}
                          />
                        );
                      })
                    ) : (
                      <div className="main-info__no-positions">
                        <h2>You don`t have opened positions</h2>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </motion.div>
            <motion.div
              custom={1}
              variants={animationTop}
              style={{ marginBottom: 10 }}
            >
              <Accordion className="main-info__tab">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className="main-info__tab-header">
                    <span>History</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <History isVisible={true} />
                </AccordionDetails>
              </Accordion>
            </motion.div>
            {/* <div
              onClick={() => setIsVisibleHistory(!isVisibleHistory)}
              className={cn("main-info__tab", {
                "main-info__tab_active":
                  activeTabIndex === 1 && isShowingPositions,
              })}
            >
              <div className="main-info__tab-header">
                <span>History</span>
                <img src={arrowDownIcon} alt="" />
              </div>
              <History isVisible={isVisibleHistory} />
            </div> */}
          </div>
        </div>
        {positions.length > 0 && <AssetsBar assets={positions} />}
      </div>
      {/* <div
        className={"main-info__drop"}
        style={isShowingPositions ? { display: "block" } : { display: "none" }}
      >
        <div
          style={{ height: isShowingPositions ? "fit-content" : "0" }}
          className={"main-info__positions-wrap"}
        >
          <div
            className={cn("main-info__positions-list", {
              "main-info__positions-list_visible": activeTabIndex === 0,
            })}
          >
            {positions.length > 0 ? (
              positions.map((position: any) => (
                <PositionItem
                  key={position.id}
                  isButton={true}
                  currentToken={position}
                  buyValueUsdt={position.usdt.toString()}
                />
              ))
            ) : (
              <div className="main-info__no-positions">
                <h2>You don`t have opened positions</h2>
              </div>
            )}
          </div>

          <History isVisible={activeTabIndex === 1} />
        </div>
      </div> */}
    </motion.div>
  );
};
