import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import cn from "classnames";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { userAPI } from "../../api/user";
import { getTotalPrice } from "../../functions/getTotalPrice";
import { useUpdatePositions } from "../../hooks/useUpdatePositions";
import { useUpdateHistory } from "../../hooks/useUpdateHistory";
import { selectUser } from "../../store/selectors/user";
import { useAppDispatch } from "../../store";
import { setUserBalanceLoading } from "../../store/reducers/user/balance";

import checkedIcon from "../../icons/checked.svg";

import "./PositionItem.scss";
import Skeleton from "../Skeleton";
import useUserId from "../../hooks/userUserId";
import classNames from "classnames";
import { setTgUser } from "../../store/reducers/user/ts";
import { fetchUserAssets } from "../../store/actionCreators/user/assets";

interface PositionItemProps {
  buyValueUsdt?: string;
  isButton?: boolean;
  currentToken: any;
  currentPrice?: any;
}

const longType = "open long position";
const shortType = "open short position";

export const PositionItem = ({
  currentToken,
  isButton = false,
}: PositionItemProps) => {
  const userId = useUserId();

  const user = useSelector(selectUser);
  const updatePositions = useUpdatePositions();
  // const updateUser = useUpdateUser(user.ethAddress);
  const updateHistory = useUpdateHistory();
  const dispatch = useAppDispatch();

  const [isPlus, setIsPlus] = useState(true);
  const [usdt, setUsdt] = useState<any>("");
  const [percent, setPercent] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);

  const onClosePositionClick = () => {
    dispatch(setUserBalanceLoading(true));
    userAPI
      .closePosition(userId, currentToken.asset.symbol, currentToken.id)
      .then(async () => {
        const amount = currentToken.amount.toString();

        updatePositions();
        updateHistory();
        const { data } = await userAPI.getTgUser(userId);

        dispatch(setTgUser(data));
        dispatch(fetchUserAssets(userId));
        toast.success(
          `You have successfully sold ${currentToken.amount} ${
            currentToken.asset.symbol
          } for ${getTotalPrice(amount, tokenPrice.toString())}`
        );
      })
      .catch(() => toast.error("Error. Try again"))
      .finally(() => {
        setTimeout(() => {
          dispatch(setUserBalanceLoading(false));
        }, 7000);
      });
  };

  function calculateProfitWithPosition(
    tokenPrice: number,
    priceBuy: number,
    quantity: number,
    position: string
  ) {
    const diff =
      position === "long" ? tokenPrice - priceBuy : priceBuy - tokenPrice;

    const profit = diff * quantity;
    const profitPercent = (diff / priceBuy) * 100;

    profit > 0 ? setIsPlus(true) : setIsPlus(false);

    setPercent(Number(profitPercent.toFixed(2)));
    setUsdt(Number(profit.toFixed(2)));
  }

  useEffect(() => {
    (async function () {
      const res = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${currentToken?.asset.symbol}USDT`
      );
      const data = await res.json();
      setTokenPrice(data.price);
    })();
  }, [currentToken, setTokenPrice]);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://fstream.binance.com/stream?streams=${currentToken?.asset.symbol.toLowerCase()}usdt@ticker`
    );
    ws.onopen = () => {
      ws.onmessage = (event) => {
        const { data } = JSON.parse(event.data);

        setTokenPrice(Number(data.c));
      };
    };
  }, [currentToken]);

  useEffect(() => {
    tokenPrice &&
      calculateProfitWithPosition(
        tokenPrice,
        currentToken.price,
        currentToken.amount,
        currentToken.type
      );
  }, [tokenPrice, currentToken]);

  return (
    <div className={cn("position")}>
      <div className="position__row">
        <div className="position__info">
          <div className="position__enter-date">
            {currentToken.date
              ? format(currentToken?.date, "yyyy-MM-dd hh:mm")
              : ""}
          </div>

          <div className="position__info-coin">
            <img
              className="position__info-coin-icon"
              src={`cryptoicons/${currentToken?.asset?.symbol}.png`}
              alt="коин"
            />
            <div className="position__info-coin-symbol">
              {currentToken?.asset?.symbol}
            </div>
          </div>
          <div className="position__info-price">
            <p>
              <span>{currentToken?.amount}</span> BTC |{" "}
              <span>{tokenPrice && Number(tokenPrice).toFixed(2)}</span> USDT
            </p>
          </div>
          <div
            className={classNames(
              "position__info-percent",
              !isPlus ? "position__info-percent_red" : ""
            )}
          >
            <p>
              {usdt} <span>USDT</span> {percent}%
            </p>
          </div>
        </div>
        {tokenPrice ? (
          <div className="position__type-wrap">
            <div
              className={classNames(
                "position__type",
                currentToken?.type === shortType ? "position__type_red" : ""
              )}
            >
              {currentToken?.type === shortType
                ? "Open short position"
                : "Open long position"}
            </div>
            {currentToken.take_profit || currentToken.stop_loss ? (
              <>
                <div className="position__tp">
                  TP/SL <img src={checkedIcon} alt="" />
                </div>
                <div className="position__diff">
                  {currentToken.take_profit ? currentToken.take_profit : "0"} /{" "}
                  {currentToken.stop_loss ? currentToken.stop_loss : "0"} USDT
                </div>
              </>
            ) : null}
          </div>
        ) : (
          <div style={{ marginLeft: "auto" }}>
            <Skeleton width={170} height={50} />
          </div>
        )}
      </div>

      {isButton && (
        <div
          className="position__button text_16"
          onTouchStart={onClosePositionClick}
        >
          Close position
        </div>
      )}
    </div>
  );
};
