import React, { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import { ToastContainer } from "react-toastify";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { useExpand } from "@vkruglikov/react-telegram-web-app";

import { userAPI } from "./api/user";
import { securityAPI } from "./api/security";
import { useAppDispatch } from "./store";
import { setIsOpenYieldModal, setRef, setUser } from "./store/reducers/user";
import { selectIsOpenYieldModal, selectUser } from "./store/selectors/user";
import {
  setHasOpenedPositions,
  setUserBalanceLoading,
  setUserTotalBalance,
} from "./store/reducers/user/balance";
import { selectHasOpenedPositions } from "./store/selectors/user/balance";
import { selectUserAssets } from "./store/selectors/user/assets";
import { fetchUserAssets } from "./store/actionCreators/user/assets";
import { setIsLoading } from "./store/reducers/loading";
import { selectisLoading, selectIsOnboarding } from "./store/selectors/loading";
import useUserId from "./hooks/userUserId";
import { setTgUser } from "./store/reducers/user/ts";
import { selectTgUser } from "./store/selectors/user/tg";
import btcCoin from "./icons/history-btc.svg";

import percentUp from "./icons/percent-up.svg";
import { ReactComponent as CloseIcon } from "./icons/close.svg";

import { Dashboard } from "./pages/Dashboard";
import Leaderboard from "./pages/Leaderboard";
import { Refferals } from "./pages/Refferals";
import Earn from "./pages/Earn";
import { Trade } from "./pages/Trade";
import { Menu } from "./components/Menu";
import { Preloader } from "./components/Preloader";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { FirstScreen } from "./pages/Onboarding/Screens/First";
import { SecondScreen } from "./pages/Onboarding/Screens/Second";
import { ThirdScreen } from "./pages/Onboarding/Screens/Third";
import { FourthScreen } from "./pages/Onboarding/Screens/Fourth";
import { FifthScreen } from "./pages/Onboarding/Screens/Fifth";
import { SixthScreen } from "./pages/Onboarding/Screens/Sixth";
import { SeventhScreen } from "./pages/Onboarding/Screens/Seventh";
import { EightsScreen } from "./pages/Onboarding/Screens/Eights";
import { NinthScreen } from "./pages/Onboarding/Screens/Ninth";

import { Onboarding } from "./pages/Onboarding";
import { setIsChartConnected } from "./store/reducers/chart";
import { fetchTestAssets } from "./store/actionCreators/assets";
import { selectTestAssets } from "./store/selectors/assets";
import { Toolbar } from "./components/Toolbar";

export const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userId = useUserId();
  const [isExpanded, expand] = useExpand();

  const isOpenYieldModal = useSelector(selectIsOpenYieldModal);
  const hasOpenedPositions = useSelector(selectHasOpenedPositions);
  const user = useSelector(selectTgUser);
  const userAssets = useSelector(selectUserAssets);
  const isLoading = useSelector(selectisLoading);
  const isOnboarding = useSelector(selectIsOnboarding);
  const testAssets = useSelector(selectTestAssets);

  const [showAddEmailModal, setshowAddEmailModal] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const ref = queryParameters.get("ref");

  const showModal = useRef(showAddEmailModal);

  showModal.current = showAddEmailModal;

  useEffect(() => {
    if (ref) {
      dispatch(setRef(ref));
    }
  }, [ref]);

  useEffect(() => {
    (async function () {
      try {
        dispatch(setIsLoading(true));
        const { data } = await userAPI.getTgUser(userId);
        dispatch(setTgUser(data));
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          dispatch(setIsLoading(false));
        }, 1000);
      }
    })();
  }, [userId, setTgUser, dispatch]);

  const [currentBalance, setCurrentBalance] = useState<any>([]);

  useEffect(() => {
    dispatch(fetchUserAssets(userId));
  }, [dispatch]);

  useEffect(() => {
    if (hasOpenedPositions) {
      dispatch(setUserBalanceLoading(true));
      setTimeout(() => {
        dispatch(setUserBalanceLoading(false));
      }, 5000);
    }
  }, [hasOpenedPositions]);

  useEffect(() => {
    dispatch(setUserTotalBalance(0));
    const someArray: any = [];

    userAssets?.map((item: any) => {
      if (Number(item.amount) > 0) {
        const objfield = { symbol: item.asset.symbol, amount: item.amount };

        someArray.push(objfield);
      }
    });
    if (someArray.length > 0) {
      setCurrentBalance(someArray);
      dispatch(setHasOpenedPositions(true));
    } else {
      setCurrentBalance([]);
      dispatch(setHasOpenedPositions(false));
    }
  }, [userId, userAssets]);

  useEffect(() => {
    dispatch(fetchTestAssets());
  }, []);

  useEffect(() => {
    console.log(testAssets);
  }, [testAssets]);

  useEffect(() => {
    dispatch(setUserTotalBalance(0));

    if (hasOpenedPositions) {
      const id = setInterval(() => {
        getTotalBalance(currentBalance).then((totalBalance) => {
          if (totalBalance) {
            dispatch(setUserTotalBalance(totalBalance.toFixed(6)));
          }
        });
      }, 3000);

      return () => {
        clearInterval(id);
      };
    } else {
      if (user) {
        dispatch(setUserTotalBalance(user.usdt));
      }
    }
  }, [currentBalance, hasOpenedPositions, user]);

  async function getTotalBalance(balances: any) {
    if (balances.length > 0) {
      const symbols = balances.map((balance: any) => `"${balance.symbol}USDT"`);
      const uniqueSymbols = [
        ...new Map(symbols.map((item) => [item, item])).values(),
      ];

      const prices = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbols=[${uniqueSymbols.join(
          ","
        )}]`
      ).then((response) => response.json());

      const totalBalance = balances.reduce((total: any, balance: any) => {
        const price = prices.find(
          (p: any) => p.symbol === `${balance.symbol}USDT`
        ).price;
        const usdValue = parseFloat(price) * parseFloat(balance.amount);

        return total + usdValue;
      }, 0);
      return totalBalance;
    }
  }

  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, [isExpanded, expand]);

  useEffect(() => {
    if (isOnboarding) {
      navigate("/onboarding/1");
    }
  }, [isOnboarding]);

  useEffect(() => {
    if (!document.getElementById("tradingview-widget-loading-script")) {
      const tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;
        document.head.appendChild(script);
      });
      tvScriptLoadingPromise.then(() => dispatch(setIsChartConnected(true)));
    }
  }, [setIsChartConnected]);

  if (isLoading) {
    return <Preloader />;
  }
  if (isOnboarding) {
    return (
      <Routes>
        <Route path={"/onboarding"} element={<Onboarding />}>
          <Route path={"1"} element={<FirstScreen />} />
          <Route path={"2"} element={<SecondScreen />} />
          <Route path={"3"} element={<ThirdScreen />} />
          <Route path={"4"} element={<FourthScreen />} />
          <Route path={"5"} element={<FifthScreen />} />
          <Route path={"6"} element={<SixthScreen />} />
          <Route path={"7"} element={<SeventhScreen />} />
          <Route path={"8"} element={<EightsScreen />} />
          <Route path={"9"} element={<NinthScreen />} />
        </Route>
      </Routes>
    );
  } else {
    return (
      <>
        <main className={"main-page"} style={{ paddingBottom: 30 }}>
          <Routes>
            <Route path={"/"} element={<Dashboard />} />
            <Route path={"/trade"} element={<Trade />} />
            <Route path={"/leaders"} element={<Leaderboard />} />
            <Route path={"/refferals"} element={<Refferals />} />
            <Route path={"/earn"} element={<Earn />} />
          </Routes>
        </main>
        <Modal isOpen={isOpenYieldModal} className="yield-modal">
          <div
            className="yield-modal-close"
            onClick={() => dispatch(setIsOpenYieldModal(false))}
          >
            <CloseIcon />
          </div>
          <div className="yield-modal__item">
            <div className="yield-modal__item-coin">
              <img src={btcCoin} alt="" width={32} height={32} />
              <span>BTC</span>
            </div>
            <div className="yield-modal__item-profit">
              <span>$23,421.54 | 9.63% </span>
              <img src={percentUp} alt="" />
            </div>
          </div>
        </Modal>
        <ToastContainer position="bottom-right" theme="dark" />

        <Menu />
      </>
    );
  }
};
