// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../icons/preloader-gradient.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader__bg {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.preloader__main {
  height: 80vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -100px;
  border-radius: 69px 69px 0px 0px;
}
.preloader__main-wrap {
  max-width: 235px;
  margin: 0 auto;
  text-align: center;
}
.preloader__main-title {
  margin-top: 50px;
  margin-bottom: 10px;
  display: inline-block;
  color: #5cee9c;
  font-weight: 500;
  font-size: 24px;
}
.preloader__main-text {
  color: #bfbfbf;
  font-weight: 500;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Preloader/Preloader.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;AAAJ;AAEE;EACE,YAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,6BAAA;EACA,gCAAA;AAAJ;AACI;EACE,gBAAA;EAEA,cAAA;EACA,kBAAA;AAAN;AAEI;EACE,gBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAAN;AAEI;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AAAN","sourcesContent":[".preloader {\n  &__bg {\n    margin-top: 100px;\n    display: flex;\n    justify-content: center;\n  }\n  &__main {\n    height: 80vh;\n    background-image: url(\"../../icons/preloader-gradient.png\");\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: 0 -100px;\n    border-radius: 69px 69px 0px 0px;\n    &-wrap {\n      max-width: 235px;\n\n      margin: 0 auto;\n      text-align: center;\n    }\n    &-title {\n      margin-top: 50px;\n      margin-bottom: 10px;\n      display: inline-block;\n      color: #5cee9c;\n      font-weight: 500;\n      font-size: 24px;\n    }\n    &-text {\n      color: #bfbfbf;\n      font-weight: 500;\n      font-size: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
