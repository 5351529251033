import { combineReducers } from "@reduxjs/toolkit";

import { userReducer } from "./user";
import { tgUserReducer } from "./user/ts";

import { userAssetsReducer } from "./user/assets";
import { userFavouritesReducer } from "./user/favourites";
import { userBalanceReducer } from "./user/balance";
import { userPositionsReducer } from "./user/positions";
import { userHistoryReducer } from "./user/history";
import { chartReducer } from "./chart";
import { loadingReducer } from "./loading";
import { tradeReducer } from "./trade";
import { assetsReducer } from "./assets";
import { modalsReducer } from "./modals";
import { earnReducer } from "./earn";

export const rootReducer = combineReducers({
  userReducer,
  userAssetsReducer,
  userBalanceReducer,
  userPositionsReducer,
  userHistoryReducer,
  chartReducer,
  tgUserReducer,
  loadingReducer,
  tradeReducer,
  userFavouritesReducer,
  assetsReducer,
  modalsReducer,
  earnReducer,
});
