// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  display: flex;
  align-items: center;
  color: #fff;
  position: sticky;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px -9px 8px 0px rgba(39, 39, 39, 0.9882352941);
  background-color: #000;
  border-top: 1px solid #565656;
  padding: 10px 22px 15px 22px;
}
.menu__tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu__tab-bg {
  position: absolute;
  top: 5px;
  z-index: 0;
  opacity: 0.6;
  background: radial-gradient(68.29% 68.29% at 51.22% 51.22%, #cbcbcb 0%, rgba(0, 0, 0, 0) 100%);
}
.menu__tab svg {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,SAAA;EACA,WAAA;EACA,8BAAA;EACA,2DAAA;EACA,sBAAA;EACA,6BAAA;EACA,4BAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAEJ;AAAI;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,8FAAA;AAEN;AAII;EACE,WAAA;EACA,YAAA;AAFN","sourcesContent":[".menu {\n  display: flex;\n  align-items: center;\n  color: #fff;\n  position: sticky;\n  bottom: 0;\n  width: 100%;\n  justify-content: space-between;\n  box-shadow: 0px -9px 8px 0px #272727fc;\n  background-color: #000;\n  border-top: 1px solid #565656;\n  padding: 10px 22px 15px 22px;\n  &__tab {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    &-bg {\n      position: absolute;\n      top: 5px;\n      z-index: 0;\n      opacity: 0.6;\n      background: radial-gradient(\n        68.29% 68.29% at 51.22% 51.22%,\n        #cbcbcb 0%,\n        rgba(0, 0, 0, 0) 100%\n      );\n    }\n    svg {\n      width: 24px;\n      height: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
