// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".dashboard {\n  min-height: 100vh;\n  // &__toolbar {\n  //   position: sticky;\n  //   top: 30px;\n  //   z-index: 99999999;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
