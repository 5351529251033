import React from "react";

import btc from "../../icons/onboarding-coin.svg";

import "./OnboardingCoin.scss";
import classNames from "classnames";

interface Props {
  className?: string;
}

export const OnboardingCoin = ({ className }: Props) => {
  return (
    <div className={classNames("onboarding-coin", className)}>
      <div className="onboarding-coin__btc">
        <img src={btc} alt="" width={40} height={40} />
        <div className="onboarding-coin__btc-symbol">
          <span>BTC</span>
          <p>Bitcoin</p>
        </div>
      </div>
      <div className="onboarding-coin__amount">
        <span>$124413</span>
        <p>3,5%</p>
      </div>
    </div>
  );
};
