import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DailyReward, SubRewards } from "../../types/earn";

interface State {
  dailyRewards: DailyReward[];
  subRewards: SubRewards;
}

const initialState: State = {
  dailyRewards: [],
  subRewards: {} as SubRewards,
};

export const earnSlice = createSlice({
  name: "earn",
  initialState,
  reducers: {
    setDailyRewards(state, action: PayloadAction<DailyReward[]>) {
      state.dailyRewards = action.payload;
    },
    setSubRewards(state, action: PayloadAction<SubRewards>) {
      state.subRewards = action.payload;
    },
  },
});

export const { setDailyRewards, setSubRewards } = earnSlice.actions;
export const earnReducer = earnSlice.reducer;
