// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assets-bar__bar {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
.assets-bar__bar div {
  height: 12px;
}
.assets-bar__bar_one div {
  border-radius: 20px;
}
.assets-bar__bar_not-one div {
  height: 12px;
}
.assets-bar__bar_not-one div:first-child {
  border-radius: 20px 0 0 20px;
}
.assets-bar__bar_not-one div:last-child {
  border-radius: 0 20px 20px 0;
}
.assets-bar__list-row {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
}
.assets-bar__list-row::-webkit-scrollbar {
  display: none;
}
.assets-bar .main-info__list-item {
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/AssetsBar/AssetsBar.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAIM;EACE,mBAAA;AAFR;AAOM;EACE,YAAA;AALR;AAMQ;EACE,4BAAA;AAJV;AAOQ;EACE,4BAAA;AALV;AAWE;EACE,WAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AATJ;AAUI;EACE,aAAA;AARN;AAYE;EACE,kBAAA;AAVJ","sourcesContent":[".assets-bar {\n  &__bar {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    margin-top: 20px;\n\n    div {\n      height: 12px;\n    }\n\n    &_one {\n      div {\n        border-radius: 20px;\n      }\n    }\n\n    &_not-one {\n      div {\n        height: 12px;\n        &:first-child {\n          border-radius: 20px 0 0 20px;\n        }\n  \n        &:last-child {\n          border-radius: 0 20px 20px 0;\n        }\n      }\n    }\n  }\n\n  &__list-row {\n    width: 100%;\n    max-width: 100%;\n    display: flex;\n    align-items: center;\n    margin-top: 10px;\n    overflow-x: scroll;\n    scrollbar-width: none;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n\n  .main-info__list-item {\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
