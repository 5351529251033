// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-balance {
  display: flex;
  align-items: center;
}
.user-balance__percent {
  margin-left: 12px;
  color: #4DCD7D;
}`, "",{"version":3,"sources":["webpack://./src/components/UserBalance/UserBalance.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".user-balance {\n  display: flex;\n  align-items: center;\n\n  &__percent {\n    margin-left: 12px;\n    color: #4DCD7D;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
