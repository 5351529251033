import axios from "axios";

const assets = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API + "assets",
});

export const assetsAPI = {
  getAllAssets: () => {
    return assets.get(`/`);
  },
  getPositions: (userId: number) => {
    return assets.get(`/stats/${userId}`);
  },
  getSellHistory: (ethAddress: string, page: number) => {
    return axios.get(
      `${process.env.REACT_APP_PUBLIC_API}history/get_sell_history/?ethAddress=${ethAddress}&page=${page}`
    );
  },
  getHistory: (userId: number) => {
    return axios.get(
      `${process.env.REACT_APP_PUBLIC_API}history/users/${userId}`
    );
  },

  getTopUsers: () => {
    return assets.get(
      `${process.env.REACT_APP_PUBLIC_API}users/get_top_users_with_pnl/`
    );
  },
  getUserPosition: (userId: number) => {
    return assets.get(
      `${process.env.REACT_APP_PUBLIC_API}users/user_position/${userId}`
    );
  },
};
