import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { userAPI } from "../../api/user";
import { selectUser, selectUsername } from "../../store/selectors/user";
import { useAppDispatch } from "../../store";
import { useMediaQuery } from "../../hooks/useMatchMedia";

import { ReactComponent as Logo } from "../../icons/logo.svg";
import { ReactComponent as Info } from "../../icons/onboarding-info.svg";

import "./Header.scss";
import { setUserName } from "../../store/reducers/user";
import { setIsOnboarding } from "../../store/reducers/loading";

export const Header = () => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const username = useSelector(selectUsername);
  const navigate = useNavigate();

  const navigator = useNavigate();
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery("(max-width: 996px)");
  const [inputValue, setInputValue] = useState<string>("");

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isVisibleForm, setIsVisibleForm] = useState<boolean>(false);

  const toggleIsVisibleForm = () => {
    setIsVisibleForm(!isVisibleForm);
  };

  const onQuestionClick = () => {
    navigate("/onboarding/1");
    dispatch(setIsOnboarding(true));
  };

  return (
    <header className="header">
      <div className="header__container">
        <Link to={"/"}>
          <Logo />
        </Link>
        <div onClick={onQuestionClick}>
          <Info />
        </div>
      </div>
    </header>
  );
};
