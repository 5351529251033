import { useState } from "react";
import info from "../../icons/balance-info-sm.svg";
import "./TradeOpenPosition.scss";
import { useSelector } from "react-redux";
import { selectTgUser } from "../../store/selectors/user/tg";

interface Props {
  withButton?: boolean;
}
const initialInputValue = "1";

const breakpoints = [0, 25, 50, 75, 100];
const currentPrice = 2600;

const TradeOpenPosition = ({ withButton }: Props) => {
  const tgUser = useSelector(selectTgUser);

  const [long, setLong] = useState(true);
  const [short, setShort] = useState(false);
  const [value, setValue] = useState(0);

  const [buyCountValue, setBuyCountValue] = useState(initialInputValue);
  const [totalValue, setTotalValue] = useState<any>(0);
  const [maxInputValue, setMaxInputValue] = useState(
    tgUser?.usdt / Number(currentPrice)
  );
  const [isHasOpenedPosition, setIsHasOpenedPosition] = useState(false);

  const calculateValue = (clientX: number, slider: HTMLDivElement) => {
    const rect = slider.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const newValue = Math.round((offsetX / rect.width) * 100);
    return Math.max(0, Math.min(newValue, 100));
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const slider = e.currentTarget;
    const newValue = calculateValue(e.clientX, slider);
    setValue(newValue);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const slider = e.currentTarget;
    const touch = e.touches[0];
    const newValue = calculateValue(touch.clientX, slider);
    setValue(newValue);
  };

  const handleBuyLong = () => {
    setLong(true);
    setShort(false);
  };

  const handleSellShort = () => {
    setLong(false);
    setShort(true);
  };

  return (
    <div className="trade_open_position">
      <div className="trade_open_position__header">
        <button
          className={`trade_open_position__header_button ${
            long && "trade_open_position__header_button_long"
          }`}
          onClick={handleBuyLong}
        >
          Buy/Long
        </button>
        <button
          className={`trade_open_position__header_button ${
            short && "trade_open_position__header_button_short"
          }`}
          onClick={handleSellShort}
        >
          Sell/Short
        </button>
      </div>
      <div className="trade_open_position__wrapper">
        <div className="trade_open_position__wrapper_head">
          <div className="trade_open_position__wrapper_balance">
            <div className="trade_open_position__wrapper_balance_text">
              Availible:
            </div>
            <div className="trade_open_position__wrapper_balance_number">
              {tgUser?.usdt}
            </div>
            <div className="trade_open_position__wrapper_balance_coin">
              USDT
            </div>
          </div>
          <div className="trade_open_position__wrapper_market">
            <div className="trade_open_position__wrapper_market_text">
              Market
            </div>
            <button className="trade_open_position__wrapper_market_icon">
              <img src={info} alt="" />
            </button>
          </div>
        </div>
        <div className="trade_open_position__wrapper_option">
          <div className="trade_open_position__wrapper_option_up">
            <div className="trade_open_position__wrapper_option_selection">
              <button className="trade_open_position__wrapper_option_button">
                -
              </button>
              <div className="trade_open_position__wrapper_option_center">
                <div className="trade_open_position__wrapper_option_center_input">
                  100
                </div>
                <div className="trade_open_position__wrapper_option_coin">
                  USDT
                </div>
              </div>
              <button className="trade_open_position__wrapper_option_button">
                +
              </button>
            </div>
            <button className="trade_open_position__wrapper_option_button-all">
              ALL
            </button>
          </div>
          <div className="trade_open_position__wrapper_option_bottom">
            <div className="trade_open_position__wrapper_balance">
              <div className="trade_open_position__wrapper_balance_text">
                You will recieve
              </div>
              <div className="trade_open_position__wrapper_balance_coin">
                BTC:
              </div>
              <div className="trade_open_position__wrapper_balance_number">
                0,00000453
              </div>
            </div>
          </div>
        </div>
        <div className="trade_open_position__slider-container">
          <div
            className="trade_open_position__tooltip"
            style={{ left: `${value}%` }}
          >
            {value}%
          </div>
          <div
            className="trade_open_position__slider"
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
            onMouseLeave={() => setValue(value)}
          >
            <div
              className="trade_open_position__track"
              style={{ width: `${value}%` }}
            />
            <div className="trade_open_position__points">
              {breakpoints.map((point) => (
                <div
                  key={point}
                  className={`trade_open_position__point ${
                    value >= point ? "trade_open_position__point_active" : ""
                  }`}
                  style={{ left: `${point}%` }}
                />
              ))}
            </div>
            <div
              className="trade_open_position__thumb"
              style={{ left: `${value}%` }}
            />
          </div>
        </div>
      </div>

      {withButton && (
        <button
          className="trade_open_position__submit"
          style={{
            backgroundColor:
              value > 0 && long
                ? "#5CEE9C"
                : value > 0 && short
                ? "#F01C23"
                : "#BBBBBB",
          }}
        >
          Open position
        </button>
      )}
    </div>
  );
};

export default TradeOpenPosition;
