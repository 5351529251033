import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";

import "./Eights.scss";
import TradeSelect from "../../../../components/TradeSelect";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";

export const EightsScreen = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);
  };
  return (
    <motion.div
      className="eights-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <motion.div variants={animationCenter} custom={1.5}>
        <div className="eights-screen__coin">
          <TradeSelect />
        </div>
        <Steps
          path="/onboarding/9"
          title="Coins"
          subtitle="Проверяйте общий и обычный баланс"
          page="4"
          withoutButton={true}
        />
      </motion.div>
      <div className="onboarding__btn ">
        <button onClick={() => onNavigate("/onboarding/9")}>
          {/* {Number(pathname) > 7 ? "Начать торговать" : "continue"} */}
          Начать торговать
        </button>
      </div>
    </motion.div>
  );
};
