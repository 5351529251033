import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isLoading: boolean;
  isOnboarding: boolean;
}

const initialState: State = {
  isLoading: false,
  isOnboarding: true,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsOnboarding(state, action: PayloadAction<boolean>) {
      state.isOnboarding = action.payload;
    },
  },
});

export const { setIsLoading, setIsOnboarding } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
