// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-screen {
  padding: 15px;
  height: 100vh;
  position: relative;
}
.first-screen__balance {
  display: flex;
  margin-top: 210px;
}

@media screen and (max-height: 749px) {
  .first-screen__balance {
    margin-top: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/First/First.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,iBAAA;AAEJ;;AACA;EAEI;IACE,gBAAA;EACJ;AACF","sourcesContent":[".first-screen {\n  padding: 15px;\n  height: 100vh;\n  position: relative;\n  &__balance {\n    display: flex;\n    margin-top: 210px;\n  }\n}\n@media screen and (max-height: 749px) {\n  .first-screen {\n    &__balance {\n      margin-top: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
