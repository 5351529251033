// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fifth-screen__balance {
  margin-bottom: 10px;
  display: flex;
}
.fifth-screen__coin {
  box-shadow: -5px -4px 20px 0px rgba(129, 161, 224, 0.3411764706);
}
.fifth-screen__coin-wrap {
  padding: 20px;
  margin-top: 90px;
}

@media screen and (max-height: 779px) {
  .fifth-screen {
    height: 100vh;
  }
  .fifth-screen__coin-wrap {
    margin-top: 40px;
  }
  .fifth-screen .steps {
    position: static;
  }
  .fifth-screen .onboarding__btn {
    position: static;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Onboarding/Screens/Fifth/Fifth.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,aAAA;AAAJ;AAEE;EACE,gEAAA;AAAJ;AAEI;EACE,aAAA;EACA,gBAAA;AAAN;;AAIA;EACE;IACE,aAAA;EADF;EAGI;IACE,gBAAA;EADN;EAIE;IACE,gBAAA;EAFJ;EAIE;IACE,gBAAA;EAFJ;AACF","sourcesContent":[".fifth-screen {\n  &__balance {\n    margin-bottom: 10px;\n    display: flex;\n  }\n  &__coin {\n    box-shadow: -5px -4px 20px 0px #81a1e057;\n\n    &-wrap {\n      padding: 20px;\n      margin-top: 90px;\n    }\n  }\n}\n@media screen and (max-height: 779px) {\n  .fifth-screen {\n    height: 100vh;\n    &__coin {\n      &-wrap {\n        margin-top: 40px;\n      }\n    }\n    .steps {\n      position: static;\n    }\n    .onboarding__btn {\n      position: static;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
