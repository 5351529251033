// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trade-modal {
  height: 82vh;
  background-color: #242830;
  color: #fff;
  overflow-y: scroll;
  position: relative;
}
.trade-modal__close {
  position: absolute;
  width: 30px;
  top: 15px;
  right: 20px;
}
.trade-modal__header {
  position: sticky;
  z-index: 9999;
  top: 0;
  margin-top: 40px;
}
.trade-modal__main {
  padding: 15px;
}
.trade-modal__main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.trade-modal__main-header span {
  color: #bfbfbf;
}
.trade-modal__main-header-name {
  display: flex;
  align-items: center;
}
.trade-modal__main-header-name span {
  display: flex;
}
.trade-modal__main-header-name span img {
  margin-left: 5px;
}
.trade-modal__main-header-name span:first-child {
  margin-right: 15px;
}
.trade-modal__main-coins {
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/components/TradeModal/TradeModal.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;AAEJ;AAAE;EACE,gBAAA;EACA,aAAA;EACA,MAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;AAAI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAEN;AAAM;EACE,cAAA;AAER;AAAM;EACE,aAAA;EACA,mBAAA;AAER;AADQ;EACE,aAAA;AAGV;AADU;EACE,gBAAA;AAGZ;AADU;EACE,kBAAA;AAGZ;AAEI;EACE,kBAAA;AAAN","sourcesContent":[".trade-modal {\n  height: 82vh;\n  background-color: #242830;\n  color: #fff;\n  overflow-y: scroll;\n  position: relative;\n  &__close {\n    position: absolute;\n    width: 30px;\n    top: 15px;\n    right: 20px;\n  }\n  &__header {\n    position: sticky;\n    z-index: 9999;\n    top: 0;\n    margin-top: 40px;\n  }\n  &__main {\n    padding: 15px;\n\n    &-header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin-bottom: 20px;\n\n      span {\n        color: #bfbfbf;\n      }\n      &-name {\n        display: flex;\n        align-items: center;\n        span {\n          display: flex;\n\n          img {\n            margin-left: 5px;\n          }\n          &:first-child {\n            margin-right: 15px;\n          }\n        }\n      }\n    }\n    &-coins {\n      overflow-y: scroll;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
