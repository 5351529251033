import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";
import { OnboardingTab } from "../../../../components/OnboardingTab";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";

import "./Fourth.scss";
import { animationCenter } from "../../../../utils/animation";

export const FourthScreen = () => {
  return (
    <motion.div
      className="fourth-screen "
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="fourth-screen__balance">
        <BalanceProfitCard />
        <BalanceProfitRemainingCard />
      </div>
      <motion.div
        className="fourth-screen__tabs"
        variants={animationCenter}
        custom={1.5}
      >
        <OnboardingTab title="Open positions" />
        <OnboardingTab title="History" />
      </motion.div>
      <Steps
        path="/onboarding/5"
        title="Open positions"
        subtitle="Здесь вы можете посмотреть текущие ставки и историю"
        page="2"
      />
    </motion.div>
  );
};
