import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Asset {
  name: string;
  symbol: string;
  color: string;
  tag: string;
}

interface Position {
  id: string;
  asset: Asset;
  type: string;
  amount: number;
  price: number;
  usdt: number;
  user_eth_addr: string;
  stop_loss: number | null;
  take_profit: number | null;
}

interface UserPositionsState {
  positions: Position[];
  isShowingPositions: boolean;
}

const initialState: UserPositionsState = {
  positions: [],
  isShowingPositions: false,
};

export const userPositionsSlice = createSlice({
  name: 'userPositions',
  initialState,
  reducers: {
    setUserPositions(state, action: PayloadAction<Position[]>) {
      state.positions = action.payload;
    },
    setIsShowingPositions(state, action: PayloadAction<boolean>) {
      state.isShowingPositions = action.payload;
    },
  },
});

export const { setUserPositions, setIsShowingPositions } = userPositionsSlice.actions;
export const userPositionsReducer = userPositionsSlice.reducer;
