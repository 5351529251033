import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import {
  animationCenter,
  animationLeft,
  animationRight,
  animationTop,
} from "../../utils/animation";

import Drawer from "@mui/material/Drawer";

import calendar from "../../icons/earn_calendar.png";
import arrow from "../../icons/earn-arrow.svg";
import youtube from "../../icons/earn_youtube.png";
import insta from "../../icons/earn_insta.png";
import close from "../../icons/close-icon.png";
import claimed from "../../icons/checked.svg";

import "./Earn.scss";
import { useAppDispatch } from "../../store";
import { setIsVisibleEarnDailyModal } from "../../store/reducers/modals";
import { EarnDailyModal } from "../../components/EarnDailyModal";
import {
  claimSubReward,
  fetchSubRewards,
} from "../../store/actionCreators/earn";
import useUserId from "../../hooks/userUserId";
import { useSelector } from "react-redux";
import { selectSubRewards } from "../../store/selectors/earn";

export default function Earn() {
  const dispatch = useAppDispatch();
  const userId = useUserId();

  const subRewards = useSelector(selectSubRewards);

  const [isYoutube, setIsYoutube] = useState(false);
  const [isInsta, setIsInsta] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);

  const toggleIsYoutubeDrawer = (newOpen: boolean) => () => {
    if (subRewards.task_youtube) {
      return;
    }
    setIsYoutube(newOpen);
  };
  const toggleIsInsta = (newOpen: boolean) => () => {
    if (subRewards.task_insta) {
      return;
    }
    setIsInsta(newOpen);
  };

  const onClickYoutubeClaim = () => {
    dispatch(claimSubReward(userId, "task_youtube"));
    setIsYoutube(false);
    // dispatch(fetchSubRewards(userId));
  };

  const onClickInstaClaim = () => {
    dispatch(claimSubReward(userId, "task_insta"));
    setIsInsta(false);
    // dispatch(fetchSubRewards(userId));
  };

  useEffect(() => {
    dispatch(fetchSubRewards(userId));
  }, [dispatch]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      variants={animationCenter}
      className="earn"
    >
      <motion.h2 variants={animationTop} custom={1} className="earn__title">
        Earn more USDT
      </motion.h2>
      <motion.div variants={animationLeft} custom={1.4} className="earn__daily">
        <div
          className="earn__item earn__item_accent"
          onClick={() => dispatch(setIsVisibleEarnDailyModal(true))}
        >
          <img src={calendar} alt="" className="earn__item_img" />
          <div className="earn__item_wrapper">
            <p className="earn__item_title">Daily reward</p>
            <p className="earn__item_price">
              <img src={arrow} alt="" />
              <span>10,000</span>USDT
            </p>
          </div>
        </div>
      </motion.div>
      <div className="earn__social">
        <motion.p variants={animationTop} custom={1} className="earn__tasks">
          Tasks
        </motion.p>
        <motion.div
          variants={animationRight}
          custom={1.4}
          className="earn__item"
          onClick={toggleIsYoutubeDrawer(true)}
        >
          <img src={youtube} alt="" className="earn__item_img" />
          <div className="earn__item_wrapper">
            <p className="earn__item_title">Watch video</p>
            <p className="earn__item_price">
              <img src={arrow} alt="" />
              <span>10,000</span>USDT
            </p>
          </div>
          {subRewards.task_youtube && (
            <div style={{ marginLeft: "auto", marginRight: 30 }}>
              <img src={claimed} alt="" />
            </div>
          )}
        </motion.div>
        <motion.div
          variants={animationRight}
          custom={1.7}
          className="earn__item"
          onClick={toggleIsInsta(true)}
        >
          <img src={insta} alt="" className="earn__item_img" />
          <div className="earn__item_wrapper">
            <p className="earn__item_title">Join our instagram</p>
            <p className="earn__item_price">
              <img src={arrow} alt="" />
              <span>10,000</span>USDT
            </p>
          </div>
          {subRewards.task_insta && (
            <div style={{ marginLeft: "auto", marginRight: 30 }}>
              <img src={claimed} alt="" />
            </div>
          )}
        </motion.div>
      </div>
      <div>
        <EarnDailyModal />
        <Drawer
          open={isYoutube}
          anchor="bottom"
          onClose={toggleIsYoutubeDrawer(false)}
        >
          <div className="earn__modal">
            <button onClick={toggleIsYoutubeDrawer(false)}>
              <img src={close} alt="" className="earn__modal_close" />
            </button>
            <div className="earn__modal_line"></div>
            <img src={youtube} alt="" className="earn__modal_img" />
            <p className="earn__modal_title">Watch video</p>
            <p className="earn__modal_subtitle">
              <p className="earn__item_price">
                <img src={arrow} alt="" />
                <span>10,000 </span> USDT
              </p>
            </p>
            <a
              href="https://youtube.com"
              target="_blank"
              onClick={onClickYoutubeClaim}
            >
              <button className="earn__modal_btn">Continue</button>
            </a>

            <div className="earn__modal_bottom"></div>
          </div>
        </Drawer>
        <Drawer open={isInsta} anchor="bottom" onClose={toggleIsInsta(false)}>
          <div className="earn__modal">
            <button onClick={toggleIsInsta(false)}>
              <img src={close} alt="" className="earn__modal_close" />
            </button>
            <div className="earn__modal_line"></div>
            <img src={insta} alt="" className="earn__modal_img" />
            <p className="earn__modal_title">Join our instagram</p>
            <p className="earn__modal_subtitle">
              <p className="earn__item_price">
                <img src={arrow} alt="" />
                <span>10,000 </span> USDT
              </p>
            </p>
            <a
              href="https://instagram.com"
              target="_blank"
              onClick={onClickInstaClaim}
            >
              <button className="earn__modal_btn">Continue</button>
            </a>
            <div className="earn__modal_bottom"></div>
          </div>
        </Drawer>
      </div>
    </motion.div>
  );
}
