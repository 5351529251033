import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { assetsAPI } from "../../api/assets";
import { selectUser } from "../../store/selectors/user";
import { useAppDispatch } from "../../store";
import { selectUserHistory } from "../../store/selectors/user/history";
import { setUserHistory } from "../../store/reducers/user/history";

import "./History.scss";
import { Pagination } from "../Pagination";
import { PositionItem } from "../PositionItem";
import { HistoryItem } from "../HistoryItem/HistoryItem";
import useUserId from "../../hooks/userUserId";

interface HistoryProps {
  isVisible: boolean;
}

const rowsPerPage = 10;

export const History = ({ isVisible }: HistoryProps) => {
  const dispatch = useAppDispatch();
  const history = useSelector(selectUserHistory);
  const user = useSelector(selectUser);
  const userId = useUserId();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  useEffect(() => {
    if (history) {
      setTotalPages(Math.ceil(history.length / rowsPerPage));
    }
  }, [currentPage, rowsPerPage, history]);

  useEffect(() => {
    (async () => {
      const { data } = await assetsAPI.getHistory(userId);

      dispatch(setUserHistory(data));
    })();
  }, [setUserHistory]);

  return (
    <div className={cn("history", { history_visible: isVisible })}>
      {history
        ? history.slice(startIndex, endIndex).map((item) => {
            // @ts-ignore
            return <HistoryItem {...item} key={item.id} />;
          })
        : null}
      <Pagination setCurrentPage={setCurrentPage} totalPages={totalPages} />

      {history?.length === 0 && (
        <div className="main-info__no-positions">
          <h2>Make at least one trade to see a history</h2>
        </div>
      )}
    </div>
  );
};
