import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedTradeValue } from "../../store/selectors/trade";

import arrow from "../../icons/whiteArrow.svg";
import btc from "../../icons/onboarding-coin.svg";

import "./TradeSelect.scss";
import { useAppDispatch } from "../../store";
import { setIsVisibleTradeModal } from "../../store/reducers/trade";
import { useLocation } from "react-router-dom";

const TradeSelect = () => {
  const dispatch = useAppDispatch();

  const selectedTradeValue = useSelector(selectSelectedTradeValue);
  const location = useLocation();

  const onClick = () => {
    if (location.pathname.includes("onboarding")) {
      return;
    }
    dispatch(setIsVisibleTradeModal(true));
  };

  return (
    <div className="trade_select" onClick={onClick}>
      <div className="trade_select__wrapper">
        <div className="trade_select__wrapper_title">
          <img
            src={
              !location.pathname.includes("onboarding")
                ? `cryptoicons/${selectedTradeValue?.symbol}.png`
                : btc
            }
            alt=""
          />
          <span>
            {!location.pathname.includes("onboarding")
              ? selectedTradeValue?.symbol
              : "BTC"}
            /USDT
          </span>
        </div>
        <img
          className={"trade_select__wrapper_arrow-down"}
          src={arrow}
          alt=""
        />
      </div>
    </div>
  );
};

export default TradeSelect;
