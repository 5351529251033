import React from "react";
import classNames from "classnames";

import "./BalanceProfitCard.scss";

interface Props {
  profitChange?: any;
  currentValue?: number;
  percentChange?: number;
  style?: any;
  className?: string;
}

export const BalanceProfitCard = ({
  profitChange,
  currentValue,
  percentChange,
  className,
}: Props) => {
  return (
    <div className={classNames("balance-profit box-shadow", className)}>
      <div>
        <div className="balance-profit__title">Total</div>
        <div className="balance-profit__value">
          {currentValue ? Number(currentValue).toFixed(2) : "1,096,252.00"}{" "}
          <span>USDT</span>
        </div>
      </div>
      <div>
        {profitChange !== 0 ? (
          <div className="balance-profit__change">
            <div
              className={classNames("balance-profit__usdt", {
                "balance-profit__usdt_green": profitChange > 0,
                "balance-profit__usdt_red": profitChange < 0,
              })}
            >
              ${profitChange ? Math.abs(profitChange.toFixed(2)) : "$67"}
            </div>
            <div className="balance-profit__divider" />
            <div
              className={classNames("balance-profit__percent", {
                "balance-profit__percent_green": profitChange > 0,
                "balance-profit__percent_red": profitChange < 0,
              })}
            >
              {percentChange ? Math.abs(percentChange) : "0.01%"}%
            </div>
          </div>
        ) : (
          "-/-"
        )}
      </div>
    </div>
  );
};
