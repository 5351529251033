import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import {
  animationBottom,
  animationRight,
  animationTop,
} from "../../utils/animation";
import { assetsAPI } from "../../api/assets";

import recording from "../../icons/lb_record.svg";
import first from "../../icons/lb_first.svg";
import second from "../../icons/lb_second.svg";
import third from "../../icons/lb_third.svg";

import arrowUp from "../../icons/earn-arrow.svg";

import "./RatingTable.scss";
import useUserId from "../../hooks/userUserId";

export const RatingTable = () => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    (async function () {
      const { data } = await assetsAPI.getTopUsers();

      setUsers(data);
    })();
  }, [setUsers]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
      className="rating"
    >
      <div className="rating-title">
        <img src={recording} alt="лайф" />
        <motion.h2 custom={2} variants={animationTop} style={{ color: "#fff" }}>
          Live Rating Table
        </motion.h2>
      </div>
      <div className="rating-items">
        <div className="rating__header">
          <motion.p
            custom={1.5}
            variants={animationRight}
            className="rating__header_name"
          >
            Balance (USDT)
          </motion.p>
          <motion.p
            custom={2}
            variants={animationRight}
            className="rating__header_name"
          >
            PNL
          </motion.p>
        </div>
        {users?.map((item, i) => {
          const place = i + 1;
          return (
            <motion.div
              variants={animationBottom}
              custom={1.2}
              className="rating__item"
              key={item.user_id}
            >
              <div className="rating__place">
                {place === 1 ? (
                  <img src={first} alt="" />
                ) : place === 2 ? (
                  <img src={second} alt="" />
                ) : place === 3 ? (
                  <img src={first} alt="" />
                ) : (
                  <span>{place}</span>
                )}
              </div>
              <p className="rating__name">{item.username}</p>
              <p className="rating__balance">{item.balance.toFixed(2)}</p>
              <p className="rating__profit">
                <img src={arrowUp} alt="" />
                {item.PNL}%
              </p>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};
