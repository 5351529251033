import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "../../../types/user";

interface userState {
  user: UserType;
  username: string | null;
  isOpenYieldModal: boolean;
  ref: string | null;
  friends: any;
}

const initialState: userState = {
  user: null,
  username: null,
  isOpenYieldModal: false,
  ref: null,
  friends: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserType>) {
      state.user = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setIsOpenYieldModal(state, action: PayloadAction<boolean>) {
      state.isOpenYieldModal = action.payload;
    },
    setRef(state, action: PayloadAction<string>) {
      state.ref = action.payload;
    },
    setFriends(state, action: PayloadAction<string>) {
      state.friends = action.payload;
    },
  },
});

export const { setUser, setUserName, setIsOpenYieldModal, setRef, setFriends } =
  userSlice.actions;
export const userReducer = userSlice.reducer;
