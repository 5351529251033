import { useInitData } from "@vkruglikov/react-telegram-web-app";

export default function useUserId() {
  const [initDataUnsafe] = useInitData();

  const userId = initDataUnsafe?.user?.id
    ? initDataUnsafe?.user?.id
    : 669099609;
  return userId;
}
