import { RootState } from "../../index";

export const selectUser = ({ userReducer }: RootState) => userReducer.user;
export const selectUsername = ({ userReducer }: RootState) =>
  userReducer.username;
export const selectIsOpenYieldModal = ({ userReducer }: RootState) =>
  userReducer.isOpenYieldModal;
export const selectUserRef = ({ userReducer }: RootState) => userReducer.ref;
export const selectUserFriends = ({ userReducer }: RootState) =>
  userReducer.friends;
