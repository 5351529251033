export const animationTop = {
  hidden: {
    y: -100, 
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0, 
    opacity: 1,
    transition: { 
      delay: custom * 0.2 
    }
  })
}

export const animationCenter = {
  hidden: {
    opacity: 0,
    heigth: 0,
  },
  visible: (custom: number) => ({
    opacity: 1,
    heigth: 100,
    transition: { 
      delay: custom * 0.2
    }
  })
}

export const animationBottom = {
  hidden: {
    y: 100, 
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0, 
    opacity: 1,
    transition: { 
      delay: custom * 0.2
    }
  })
}
export const animationRight = {
  hidden: {
    x: 100, 
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0, 
    opacity: 1,
    transition: { 
      delay: custom * 0.2
    }
  })
}
export const animationLeft = {
  hidden: {
    x: -100, 
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0, 
    opacity: 1,
    transition: { 
      delay: custom * 0.2
    }
  })
}