// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balance-profit__remaining {
  color: #fff;
  min-width: 150px;
}
.balance-profit__remaining.first-screen-profit__remaining {
  box-shadow: 3px -5px 20.4px 0px rgba(129, 161, 224, 0.3411764706);
  position: relative;
  top: 30px;
  right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/BalanceProfitRemainingCard/BalanceProfitRemainingCard.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;AACF;AAAE;EACE,iEAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AAEJ","sourcesContent":[".balance-profit__remaining {\n  color: #fff;\n  min-width: 150px;\n  &.first-screen-profit__remaining {\n    box-shadow: 3px -5px 20.4px 0px #81a1e057;\n    position: relative;\n    top: 30px;\n    right: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
