import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "@mui/material";

import { selectIsVisibleTradeModal } from "../../store/selectors/trade";
import { useAppDispatch } from "../../store";
import { setIsVisibleTradeModal } from "../../store/reducers/trade";

import close from "../../icons/close-icon.png";
import swap from "../../icons/toolbar-swap.svg";

import { Toolbar } from "../Toolbar";
import { TradeCoin } from "../TradeCoin";
import { Pagination } from "../Pagination";

import "./TradeModal.scss";
import { AssetRow } from "../AssetRow";

interface Props {
  assets: any;
  searchValue: string;
  setSearchValue: any;
}

const rowsPerPage = 8;

export const TradeModal = ({ assets, searchValue, setSearchValue }: Props) => {
  const dispatch = useAppDispatch();
  const isVisibleTradeModal = useSelector(selectIsVisibleTradeModal);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const onClickClose = () => {
    dispatch(setIsVisibleTradeModal(false));
  };

  useEffect(() => {
    if (assets) {
      setTotalPages(Math.ceil(assets.length / rowsPerPage));
    }
  }, [rowsPerPage, assets]);

  return (
    <Drawer open={isVisibleTradeModal} anchor="bottom">
      <div className="trade-modal">
        <button>
          <img
            src={close}
            alt=""
            className="trade-modal__close"
            onClick={onClickClose}
          />
        </button>
        <div className="trade-modal__header">
          <Toolbar searchValue={searchValue} setSearchValue={setSearchValue} />
        </div>
        <div className="trade-modal__main">
          <div className="trade-modal__main-header">
            <div className="trade-modal__main-header-name">
              <span>
                Name <img src={swap} alt="" />
              </span>
              <span>
                Price <img src={swap} alt="" />
              </span>
            </div>

            <div className="trade-modal__main-header-price">
              <span>Last Price</span>
            </div>
          </div>
          <div className="trade-modal__main-coins">
            {assets.length ? (
              assets.map((item, index) => (
                // <TradeCoin item={item} price={price} delay={index} />
                <AssetRow
                  currentToken={item}
                  delay={index}
                  className="trade-coin"
                />
              ))
            ) : (
              <p>No assets</p>
            )}
          </div>
        </div>

        {/* <Pagination
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        /> */}
      </div>
    </Drawer>
  );
};
