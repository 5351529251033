import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import btc from "../../icons/btc-yellow.svg";

interface State {
  selectedTradeValue: any;
  isVisibleTradeModal: boolean;
  price: string | number;
  activeTab: string;
}

const initialState: State = {
  selectedTradeValue: {
    color: "#B87409",
    name: "Bitcoin",
    symbol: "BTC",
    tag: "btc",
  },
  isVisibleTradeModal: false,
  price: "0",
  activeTab: "all",
};

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    setSelectedTradeValue(state, action: PayloadAction<any>) {
      state.selectedTradeValue = action.payload;
    },
    setIsVisibleTradeModal(state, action: PayloadAction<boolean>) {
      state.isVisibleTradeModal = action.payload;
    },
    setPrice(state, action: PayloadAction<string | number>) {
      state.price = action.payload;
    },
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setSelectedTradeValue,
  setIsVisibleTradeModal,
  setPrice,
  setActiveTab,
} = tradeSlice.actions;
export const tradeReducer = tradeSlice.reducer;
