import { AppDispatch } from "../../";
import { assetsAPI } from "../../../api/assets";
import { UserType } from "../../../types/user";
import { setUserAssets } from "../../reducers/user/assets";

export const fetchUserAssets =
  (userId: number) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await assetsAPI.getPositions(userId);

      dispatch(setUserAssets(data));
    } catch (error) {
      console.log(error);
    }
  };
