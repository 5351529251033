// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-tab {
  display: flex;
  justify-content: space-between;
  background-color: #242830;
  width: 100%;
  color: #fff;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
}
.onboarding-tab span {
  text-transform: uppercase;
  font-size: 12px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/OnboardingTab/OnboardingTab.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,yBAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,yBAAA;EACA,eAAA;EACA,WAAA;AAEJ","sourcesContent":[".onboarding-tab {\n  display: flex;\n  justify-content: space-between;\n  background-color: #242830;\n  width: 100%;\n  color: #fff;\n  padding: 10px;\n  border-radius: 12px;\n  margin-bottom: 10px;\n  span {\n    text-transform: uppercase;\n    font-size: 12px;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
