// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-coin {
  width: 100%;
  background-color: #242830;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.onboarding-coin__btc {
  display: flex;
}
.onboarding-coin__btc img {
  margin-right: 5px;
}
.onboarding-coin__btc-symbol span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}
.onboarding-coin__btc-symbol p {
  font-size: 14px;
  color: #bfbfbf;
}
.onboarding-coin__amount span {
  font-weight: 500;
  color: #fff;
}
.onboarding-coin__amount p {
  color: #5cee9c;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/OnboardingCoin/OnboardingCoin.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AAAM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAER;AAAM;EACE,eAAA;EACA,cAAA;AAER;AAGI;EACE,gBAAA;EACA,WAAA;AADN;AAGI;EACE,cAAA;EACA,gBAAA;AADN","sourcesContent":[".onboarding-coin {\n  width: 100%;\n  background-color: #242830;\n  border-radius: 12px;\n  padding: 10px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  &__btc {\n    display: flex;\n    img {\n      margin-right: 5px;\n    }\n    &-symbol {\n      span {\n        color: #ffffff;\n        font-size: 16px;\n        font-weight: 600;\n      }\n      p {\n        font-size: 14px;\n        color: #bfbfbf;\n      }\n    }\n  }\n  &__amount {\n    span {\n      font-weight: 500;\n      color: #fff;\n    }\n    p {\n      color: #5cee9c;\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
