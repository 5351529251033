import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import { selectUser } from "../../store/selectors/user";
import { AssetItem } from "../../types/user/assets";
import { assetsAPI } from "../../api/assets";

import { animationBottom } from "../../utils/animation";
import { ReactComponent as SearchIcon } from "../../icons/search-icon.svg";

import { AssetRow } from "../AssetRow";
import { Pagination } from "../Pagination";

import "./AssetList.scss";
import { Toolbar } from "../Toolbar";
import { selectUserAssets } from "../../store/selectors/user/assets";
import { useAppDispatch } from "../../store";
import { setUserAssets } from "../../store/reducers/user/assets";
import { selectAssets } from "../../store/selectors/assets";
import { setAssets } from "../../store/reducers/assets";
import { setPrice } from "../../store/reducers/trade";

export interface Price {
  symbol: string;
  price: string;
}

export interface AssetListProps {
  searchValue?: string;
}

const rowsPerPage = 10;

export const AssetList = ({ searchValue }: AssetListProps) => {
  const dispatch = useAppDispatch();

  const assets = useSelector(selectAssets);

  // const [currentPage, setCurrentPage] = useState<number>(0);
  // const [totalPages, setTotalPages] = useState<number>(0);
  // const [searchValue, setSearchValue] = useState<string>("");

  // const startIndex = currentPage * rowsPerPage;
  // const endIndex = startIndex + rowsPerPage;

  useEffect(() => {
    (async () => {
      const { data } = await assetsAPI.getAllAssets();

      const filteredAssets = data.filter(
        (item) =>
          item.symbol.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      );

      dispatch(setAssets(filteredAssets));
    })();
  }, [searchValue]);

  // useEffect(() => {
  //   setCurrentPage(0);
  // }, [searchValue]);

  // useEffect(() => {
  //   if (assets) {
  //     setTotalPages(Math.ceil(assets.length / rowsPerPage));
  //   }
  // }, [rowsPerPage, assets]);

  return (
    <div className="asset-list__overflow">
      {/* <Toolbar searchValue={searchValue} setSearchValue={setSearchValue} /> */}

      <div className={"asset-list"}>
        <div className="asset-list__wrap">
          {assets.length ? (
            assets.map((token: AssetItem, index: number) => {
              if (token.symbol === "cro") return null;
              return (
                <AnimatePresence key={token.symbol}>
                  <AssetRow currentToken={token} delay={index} />;
                </AnimatePresence>
              );
            })
          ) : (
            <motion.div
              custom={1.4}
              variants={animationBottom}
              className="asset-list__empty"
            >
              <p className="asset-list__empty-title">No assets</p>
            </motion.div>
          )}
        </div>

        {/* <Pagination
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        /> */}
      </div>
    </div>
  );
};
