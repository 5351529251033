import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import {
  selectHasOpenedPositions,
  selectUserTotalBalance,
} from "../../store/selectors/user/balance";

import "./BalanceProfit.scss";
import { selectTgUser } from "../../store/selectors/user/tg";
import { BalanceProfitCard } from "../BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../BalanceProfitRemainingCard";

const BalanceProfit = () => {
  // const user = useSelector(selectUser);
  const tgUser = useSelector(selectTgUser);

  const totalBalance = useSelector(selectUserTotalBalance);
  const hasOpenedPositions = useSelector(selectHasOpenedPositions);

  const [percentChange, setPercentChange] = useState<any>();
  const [profitChange, setProfitChange] = useState<any>();
  const [currentValue, setCurrentValue] = useState<any>(0);

  useEffect(() => {
    if (totalBalance) {
      const localAmount = hasOpenedPositions
        ? Number(totalBalance) + tgUser?.usdt
        : tgUser?.usdt;

      const amount = ((localAmount - 1000000) / 1000000) * 100;

      setCurrentValue(localAmount.toFixed(6));
      setPercentChange(amount.toFixed(2));
      setProfitChange(localAmount - 1000000);
    }
  }, [totalBalance, hasOpenedPositions, tgUser]);

  return (
    <>
      <div className="balance-wrapper">
        <BalanceProfitCard
          currentValue={currentValue}
          profitChange={profitChange}
          percentChange={percentChange}
          style={{ fontSize: 40 }}
        />
      </div>
      <BalanceProfitRemainingCard />
    </>
  );
};

export default BalanceProfit;
