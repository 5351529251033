import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoryItem } from '../../../types/user/history';

interface userHistoryState {
  history: HistoryItem[];
}

const initialState: userHistoryState = {
  history: null,
};

export const userHistorySlice = createSlice({
  name: 'userHistory',
  initialState,
  reducers: {
    setUserHistory(state, action: PayloadAction<HistoryItem[]>) {
      state.history = action.payload;
    },
  },
});

export const { setUserHistory } = userHistorySlice.actions;
export const userHistoryReducer = userHistorySlice.reducer;
