import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";
import { OnboardingTab } from "../../../../components/OnboardingTab";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";

import "./Third.scss";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";

export const ThirdScreen = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);

    setTimeout(() => {
      navigate("/onboarding/5");
    }, 1000);
  };
  return (
    <motion.div
      className="third-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="third-screen__balance">
        <BalanceProfitCard />
        <BalanceProfitRemainingCard />
      </div>
      <motion.div
        className="third-screen__tabs"
        variants={animationCenter}
        custom={1.5}
      >
        <OnboardingTab title="Open positions" className={"third-screen-tab"} />
        <OnboardingTab title="History" className={"third-screen-tab"} />
      </motion.div>
      <motion.div variants={animationCenter} custom={1.5}>
        <Steps
          path="/onboarding/4"
          title="Open positions"
          subtitle="Здесь вы можете посмотреть текущие ставки и историю"
          page="2"
          withoutButton={true}
        />
      </motion.div>
      <div className="onboarding__btn">
        <button onClick={() => onNavigate("/onboarding/4")}>
          {/* {Number(pathname) > 7 ? "Начать торговать" : "continue"} */}
          continue
        </button>
      </div>
    </motion.div>
  );
};
