import { OnboardingHeader } from "./Header";
import { Outlet } from "react-router-dom";

import "./Onboarding.scss";

export const Onboarding = () => {
  return (
    <div className="onboarding">
      <OnboardingHeader />
      <Outlet />
    </div>
  );
};
