import React from "react";

import { motion } from "framer-motion";

import { Steps } from "../../Steps";
import { OnboardingTab } from "../../../../components/OnboardingTab";
import { BalanceProfitCard } from "../../../../components/BalanceProfitCard";
import { BalanceProfitRemainingCard } from "../../../../components/BalanceProfitRemainingCard";

import "./Fifth.scss";
import { OnboardingCoin } from "../../../../components/OnboardingCoin";
import { animationCenter } from "../../../../utils/animation";
import { useNavigate } from "react-router-dom";

export const FifthScreen = () => {
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);

    setTimeout(() => {
      navigate("/onboarding/7");
    }, 1000);
  };
  return (
    <motion.div
      className="fifth-screen"
      initial="hidden"
      animate="visible"
      viewport={{ once: true }}
    >
      <div className="fifth-screen__balance">
        <BalanceProfitCard />
        <BalanceProfitRemainingCard />
      </div>
      <div className="fifth-screen__tabs">
        <OnboardingTab title="Open positions" />
        <OnboardingTab title="History" />
      </div>
      <motion.div
        className="fifth-screen__coin-wrap"
        variants={animationCenter}
        custom={1.5}
      >
        <OnboardingCoin className="fifth-screen__coin" />
      </motion.div>
      <motion.div
        className="fifth-screen__steps"
        variants={animationCenter}
        custom={1.5}
      >
        <Steps
          path="/onboarding/6"
          title="Coins"
          subtitle="Посмотрите актуальную информацию о монете"
          page="3"
          withoutButton={true}
        />
      </motion.div>
      <div className="onboarding__btn ">
        <button onClick={() => onNavigate("/onboarding/6")}>
          {/* {Number(pathname) > 7 ? "Начать торговать" : "continue"} */}
          continue
        </button>
      </div>
    </motion.div>
  );
};
