import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import classNames from "classnames";

import "./Pagination.scss";

interface PaginationProps {
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  currentPage?: number;
}

export const Pagination = ({
  setCurrentPage,
  totalPages,
  currentPage,
}: PaginationProps) => {
  const [actveButton, setActiveButton] = useState<number>(0);

  const handlePageChange = (pageNum: number) => {
    setCurrentPage(pageNum);
    setActiveButton(pageNum);
  };

  useEffect(() => {
    if (currentPage === 0) {
      handlePageChange(0);
    }
  }, [currentPage]);

  return (
    <>
      {totalPages > 1 && (
        <div className="pagination">
          {[...Array(totalPages)].map((_, i) => (
            <button
              className={classNames("pagination__button", {
                pagination__button_active: i === actveButton,
              })}
              disabled={actveButton === i}
              key={i}
              onClick={() => handlePageChange(i)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
