import { AppDispatch } from "../";
import { earnAPI } from "../../api/earn";
import { setDailyRewards, setSubRewards } from "../reducers/earn";

export const fetchDailyRewards =
  (userId: number) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await earnAPI.fetchDailyRewards(userId);

      dispatch(setDailyRewards(data));
    } catch (error) {
      console.log(error);
    }
  };
export const claimDailyReward =
  (userId: number, day: string) => async (dispatch: AppDispatch) => {
    try {
      await earnAPI.claimDailyReward(userId, day);

      // dispatch(setDailyRewards(data));
      dispatch(fetchDailyRewards(userId));
    } catch (error) {
      console.log(error);
    }
  };
export const fetchSubRewards =
  (userId: number) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await earnAPI.fetchSubRewards(userId);

      dispatch(setSubRewards(data));
    } catch (error) {
      console.log(error);
    }
  };
export const claimSubReward =
  (userId: number, task: string) => async (dispatch: AppDispatch) => {
    try {
      await earnAPI.claimSubReward(userId, task);

      dispatch(fetchSubRewards(userId));

      // dispatch(setSubRewards(data));
    } catch (error) {
      console.log(error);
    }
  };
